@import 'src/_variable';

.modal_cancel_rdv {
  .modal-content {
    padding-bottom: 16px;
  }

  .modal-body {
    h2 {
      margin-bottom: 24px;
    }

    p:not(.motif-title) {
      font-weight: normal;
      font-size: 16px;
      line-height: 22px;
    }
  }

  .form-motif {
    p {
      font-weight: normal;
      font-size: 14px;
      line-height: 16px;
      min-height: 0px;
      margin-bottom: 16px;
    }

    label {
      font-weight: normal;
      font-size: 14px;
    }
  }

  .textarea-box {
    margin-top: 15px;
    margin-bottom: 32px;
    .motif-title {
      margin-bottom: 10px;
    }
  }

  .deadline-warning {
    border: 1px solid #fb483a;
    color: #fb483a;
    display: flex;
    margin-bottom: 12px;

    .side-rect {
      background: #fb483a;
      width: 171px;
      display: flex;
      justify-content: center;
      align-items: center;

      svg {
        width: 24px;
        height: 24px;
      }
    }

    .content {
      padding: 12px 14px;

      .body-text {
        font-weight: bold;
        font-size: 14px;
        line-height: 150%;
        margin-bottom: 12px;
      }

      .tel-number {
        font-weight: normal;
        font-size: 16px;
        line-height: 22px;

        span {
          font-weight: 500;
          font-size: 18px;
          line-height: 22px;
        }
      }
    }
  }

  .form-check {
    display: flex;
    padding-top: 12px;
    label {
      font-weight: 600;
      font-size: 14px;
      line-height: 16px !important;
      margin-bottom: 32px !important;
      letter-spacing: -0.3px;
    }
  }

  .btn {
    margin-top: 0px !important;
  }

  .congrat-cancel {
    margin-top: 42px;
    p:not(.motif-title) {
      text-align: center;
      margin-bottom: 32px !important;
    }

    span {
      display: block;
      text-align: center;
      width: 100%;

      &:first-child {
        margin-bottom: 15px;
      }
    }

    button {
      height: 41px;
      width: 93px;

      @media (min-width: 768px) {
        height: 50px;
        width: 121px;
      }
    }
  }
}
