.slider-rdv.slick-slider {
  .slick-track {
    width: auto !important;

    .slick-slide {
      width: auto !important;
    }
  }

  .slick-list {
    width: 100%;
  }
}
