@import 'src/_variable';

.documents {
  .slider-ec {
    .slick-slide {
      margin-right: 0px;
    }
    .slick-list {
      overflow: visible !important;
    }

    .nav-link {
      min-width: auto;
    }
  }

  .nav-item.has-new-document {
    position: relative;

    &::after {
      content: '';
      position: absolute;
      top: -7px;
      left: -1px;
      height: 30px;
      width: 30px;
      border-radius: 50%;
      background: $new_badge;
    }
  }

  .select-filter-document {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 32px;
  }

  .skeleton {
    .search-bar {
      @media (min-width: 992px) {
        width: 349px !important;
      }
    }
    .button {
      @media (min-width: 1200px) {
        height: 60px !important;
        width: 397px !important;
      }
    }
    .line-table {
      @media (min-width: 1200px) {
        height: 46px !important;
      }
    }
  }

  .rect_info_search {
    & > div {
      padding: 16px;
      padding-bottom: 25px;
      @media (min-width: 768px) {
        padding: 40px 44px;
      }
    }
  }

  .badge-ec {
    display: inline;
    padding: 5px 6px;
  }

  .search-bar-container {
    flex-direction: column;
  }

  .rect_info_document {
    & > div {
      padding: 18px 17px 40px 17px;
      @media (min-width: 768px) {
        padding: 40px;
      }
    }
  }

  .form-search {
    border-bottom: 2px solid $bleu-edenred;

    .form-control-react-select > div {
      border-bottom: 0px;
    }

    @media (min-width: 992px) {
      max-width: 349px;
    }

    svg {
      path {
        stroke-width: 2px;
      }
    }

    .delete-icon {
      margin-right: 16px;
      opacity: 0;
      pointer-events: none;

      &.active {
        opacity: 1;
        pointer-events: auto;
      }
    }

    input {
      padding-left: 0px;
      padding-bottom: 11px;
      text-transform: justify;

      &::-webkit-input-placeholder {
        font-style: italic;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;

        @media (min-width: 768px) {
          font-size: 16px;
          line-height: 20px;
        }
      }
      &:-moz-placeholder {
        font-style: italic;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;

        @media (min-width: 768px) {
          font-size: 16px;
          line-height: 20px;
        }
      }
      &::-moz-placeholder {
        font-style: italic;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;

        @media (min-width: 768px) {
          font-size: 16px;
          line-height: 20px;
        }
      }
      &:-ms-input-placeholder {
        font-style: italic;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;

        @media (min-width: 768px) {
          font-size: 16px;
          line-height: 20px;
        }
      }

      &:focus {
        box-shadow: none;
      }
    }
  }

  .btn-download {
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    text-align: center;
    border-width: 2px;
    letter-spacing: 1px;
    margin-bottom: 20px;

    @media (min-width: 1200px) {
      padding: 15px 30px;
      font-weight: 600;
      font-size: 15px;
      line-height: 18px;
      margin-bottom: 33px;
    }
  }

  .contact {
    h5 {
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      color: $bleu-edenred;
      margin-top: 35px;

      @media (min-width: 1200px) {
        font-weight: bold;
        font-size: 20px;
        line-height: 24px;
        margin-bottom: 15px;
        margin-top: 60px;
      }
    }

    .btn {
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      text-align: center;
      padding: 8px 15px;

      @media (min-width: 1200px) {
        font-weight: 600;
        font-size: 15px;
        line-height: 18px;
        padding: 15px 44px;
      }
    }
    padding-bottom: 20px;

    @media (min-width: 1200px) {
      padding-bottom: 8px;
    }
  }
}

.modal_document {
  .modal-content {
    padding-bottom: 32px;
    .modal-body {
      margin-bottom: 0px;
      h2 {
        margin-bottom: 16px;
      }
      p {
        font-weight: normal;
        font-size: 16px;
        line-height: 22px;
        color: $bleu-edenred;
        margin-bottom: 32px;
      }
      .btn {
        font-weight: 600;
        width: 209px;
      }
    }
  }
}

.info-payement-icon {
  width: 12px;
  height: 12px;
}
