@import 'src/_variable';

.card .card-body .card-conditions-generales {
  height: 330px;
  display: flex;
  flex-direction: column;

  h4.card-title {
    margin-bottom: 32px;

    @media (min-width: 540px) {
      font-weight: bold;
      font-size: 32px;
      line-height: 39px;
      margin-top: 0px;
      margin-bottom: 36px;
      text-align: left;
    }
  }

  form {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;

    @media (min-width: 540px) {
      width: 100%;
    }
    .form-check-input {
      width: 17px;
      height: 17px;
      @media (min-width: 540px) {
        width: 20px;
        height: 20px;
      }
    }
    .form-check-label {
      font-weight: 600;
      font-size: 16px;
      line-height: 22px;
      color: $bleu-edenred;
      margin-left: 8px;
      margin-top: 5px;
    }

    button {
      font-weight: 600;
      font-size: 15px;
      line-height: 18px;
      text-align: center;
      padding-top: 15px;
      padding-bottom: 15px;

      @media (min-width: 540px) {
        max-width: 340px;
        margin: 0 auto;
        width: 100%;
      }

      &.disabled {
        background: #d6d6d6;
        border-color: #d6d6d6;
        opacity: 1;
      }
    }
  }
}
