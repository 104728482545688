.carousel-swiperjs-equipment {
  display: flex !important;
  align-items: center;
  margin-right: 17px !important;

  .arrow--prev,
  .arrow--next {
    background: #d5f0fe;
    border-radius: 100px;
    padding: 10px 21px;
    position: relative;
    width: auto;
    height: auto;

    &:hover,
    &:focus {
      background: rgb(189, 229, 250);
    }

    @media (max-width: 767px) {
      display: none;
    }
  }

  .arrow--prev {
    left: 0px;
    margin-right: 16px;
    &:before {
      font-family: 'Font Awesome 5 Free';
      font-weight: 900;
      content: '\f060';
      color: black;
      font-style: normal;
      font-style: 25px;
    }
  }

  .arrow--next {
    right: 0px;
    margin-left: 16px;
    &:before {
      font-family: 'Font Awesome 5 Free';
      font-weight: 900;
      content: '\f061';
      color: black;
      font-style: normal;
      font-style: 25px;
    }
  }
}
