@import 'src/_variable';

.rdv_passes {
  table.rdv_passes_table {
    display: block;
    background: white;
    border-radius: 10px;
    @media (min-width: 1100px) {
      display: table;
      border-radius: 0px;
    }

    tbody {
      display: block;
      padding: 16px;
      box-shadow: 0px 10px 20px rgba(208, 214, 222, 0.5);
      @media (min-width: 1100px) {
        display: table-row-group;
        box-shadow: none;
        padding: 0px;
      }

      tr {
        display: flex;
        flex-wrap: wrap;
        @media (min-width: 1100px) {
          display: table-row;
        }

        td {
          font-weight: bold;
          border: none;
          font-weight: 600 !important;
          font-size: 14px !important;
          line-height: 17px !important;
          padding-left: 5px;
          padding-right: 5px;
          @media (min-width: 1100px) {
            border-top: 1px solid #e6eaef;
          }
          img {
            width: 20px;
            height: 20px;
            margin-right: 8px;
          }

          .emoji {
            display: flex;
            align-items: center;
            font-weight: normal;
            font-size: 12px;
            line-height: 15px;
            color: $bleu-edenred;
            margin: 5px 0px;
            padding-left: 22px;

            @media (min-width: 1100px) {
              width: 130px;
              padding-left: 22px;
            }

            img {
              width: 24px;
              height: 24px;
            }
          }

          &.button_rdv {
            width: 100%;
            font-weight: 600;
            font-size: 12px;
            line-height: 15px;
            @media (min-width: 1100px) {
              width: 190px;
              &.has-opinion {
                width: 330px;
              }
            }
            @media (min-width: 1100px) {
              justify-content: center;
            }
          }
        }
      }
    }
  }

  .questionIcon {
    width: 15px !important;
    height: 15px !important;
    margin-left: 8px;
  }

  div.reactTooltipRDV {
    background: #d5f0fe;
    box-shadow: 0px 10px 20px rgba(208, 214, 222, 0.5);
    border-radius: 3px;
    max-width: 260px;
    font-weight: normal;
    font-size: 10px;
    line-height: 12px;
    color: #252525;
    padding-left: 13px;
    padding-right: 13px;

    @media (min-width: 992px) {
      max-width: 315px;
      font-size: 10px;
      line-height: 12px;
    }

    &.show {
      opacity: 1;
    }

    &::after {
      border-right-color: #d5f0fe;
      border-left-color: #d5f0fe;
      border-top: 10px solid transparent;
      border-bottom: 10px solid transparent;
      right: -10px;
      margin-top: -10px;
      border-left-width: 10px;
    }
  }
}
