@import 'src/_variable';

.questionFAQ {
  padding: 0px;
  box-shadow: 0px 5px 10px rgba(208, 214, 222, 0.4);

  // @media (min-width: 768px) {
  //   padding-bottom: 21px;
  // }

  .card-header {
    background: #ffffff;
    min-height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 0px;
    h5 {
      width: 100%;
      button {
        width: 100%;
        text-align: left;
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        text-decoration: none;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0px 5px;

        @media (min-width: 768px) {
          font-weight: bold;
          font-size: 15px;
          line-height: 18px;
          padding: 0.375rem 0.75rem;
        }

        i {
          font-size: 24px;
        }

        &:focus {
          box-shadow: none;
        }

        &.collapsed {
          i {
            transform: rotate(-90deg);
          }
        }
      }
    }
  }

  .card-body {
    padding: 10px 25px;
    @media (min-width: 768px) {
      padding: 10px 40px;
    }

    .content {
      font-weight: normal;
      font-size: 14px;
      line-height: 22px;
      color: $gris_fonce;
      margin-bottom: 25px;

      @media (min-width: 768px) {
        font-size: 15px;
        line-height: 18px;
      }

      img,
      iframe {
        height: auto;
        border-radius: 10px;
        width: 100%;
        margin-bottom: 1rem;

        @media (min-width: 567px) {
          width: calc(50% - (35px / 2));

          &:nth-child(odd) {
            margin-right: 25px;
          }
        }
      }

      .ratio {
        display: inline-block;
        position: relative;
        width: 100%;
        padding-bottom: 56.25%;
        overflow: hidden;
        border-radius: 10px;
        margin-bottom: 16px;

        @media (min-width: 567px) {
          padding-bottom: calc(56.25% / 2);
          width: calc(50% - (35px / 2));

          &:first-child {
            margin-right: 35px;
          }
        }

        iframe,
        img {
          position: absolute;
          top: 50%;
          left: 50%;
          width: 100%;
          height: 100%;
          border-radius: 10px;
          transform: translate(-50%, -50%);
        }

        img {
          height: auto;
          @media (min-width: 768px) {
            margin-bottom: 0px;
          }
        }
      }

      p {
        font-size: 14px;
        line-height: 22px;

        @media (min-width: 768px) {
          font-size: 15px;
          line-height: 18px;
        }
      }
    }

    .media {
      display: flex;
      justify-content: space-between;
      flex-direction: column;

      @media (min-width: 768px) {
        flex-direction: row;

        & > div {
          max-width: 50%;
        }
      }
    }
  }
}
