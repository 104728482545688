@import 'src/_variable';

.useful {
  margin-top: 46px;
  h5 {
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    text-decoration: none;
    color: $bleu-edenred;

    @media (min-width: 768px) {
      font-weight: bold;
      font-size: 15px;
      line-height: 18px;
    }
  }

  form {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    h5 {
      margin-bottom: 15px;
      width: 100%;

      @media (min-width: 768px) {
        margin-bottom: 12px;
      }
    }

    textarea {
      border: 2px solid #dadada;
      border-radius: 10px;
      margin-bottom: 32px;

      @media (min-width: 768px) {
        margin-bottom: 15px;
      }
    }

    .btn {
      width: 100%;
      font-weight: 600;
      font-size: 15px;
      line-height: 18px;

      @media (min-width: 768px) {
        width: 209px;
      }
    }
  }

  .buttons-choices {
    label {
      margin-bottom: 24px;
      @media (min-width: 768px) {
        margin-bottom: 38px;
      }

      &:first-child {
        margin-right: 10px;
      }
    }
    .btn {
      font-weight: 600;
      font-size: 15px;
      line-height: 18px;
      height: 40px;
      width: 103px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-width: 2px !important;
    }

    input:checked {
      & + span {
        background: $bleu-edenred;
        color: white !important;
      }
    }

    [type='radio'] {
      visibility: hidden;
      margin: 0;
      padding: 0;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
    }
  }
}
