@import 'src/_variable';

.card .card-body .card-validation-mail {
  h4.card-title {
    margin-bottom: 32px;

    @media (min-width: 540px) {
      font-weight: bold;
      font-size: 32px;
      line-height: 39px;
      margin-top: 0px;
      margin-bottom: 27px;
      text-align: left;
    }
  }

  p.card-text {
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    color: $bleu-edenred;
    text-align: left;
    margin-bottom: 16px;

    @media (min-width: 540px) {
      margin-bottom: 8px;
      padding: 0px;
      text-align: left;
    }
  }

  p.email {
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    margin-bottom: 16px;
    @media (min-width: 540px) {
      margin-bottom: 16px;
      font-size: 15px;
      line-height: 18px;
    }
  }
}
