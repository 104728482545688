@import 'src/_variable';

.Toastify__toast-container {
  width: 385px !important;
  z-index: 9999999999 !important;
}
.Toastify__toast {
  border-left: 4px solid #f6f7f8;
  box-sizing: border-box;
  border-radius: 5px !important;
  background: #fff !important;
  min-height: 0px !important;
  color: #000;
  z-index: 999999;

  .Toastify__toast-body {
    font-weight: bold;
    font-size: 15px;
    line-height: 25px;
    letter-spacing: -0.3px;
    color: #162056;

    & > div {
      display: flex;
      align-items: center;

      i {
        font-size: 20px;
        margin-right: 12px;
      }
    }
  }

  .Toastify__close-button {
    color: #000;
    opacity: 1;
    outline: none;

    svg {
      width: 20px;
      height: 20px;
    }
  }
}
.Toastify__toast--default {
  border-left-color: #fff;

  .Toastify__progress-bar {
    //background: #fff;
  }
}
.Toastify__toast--info {
  border-left-color: #3498db;

  .Toastify__progress-bar {
    //background: #3498db;
  }
}
.Toastify__toast--success {
  border-left-color: $validation;

  .Toastify__progress-bar {
    //background: #07bc0c;
  }
}
.Toastify__toast--warning {
  border-left-color: #f1c40f;

  .Toastify__progress-bar {
    //background: #f1c40f;
  }
}
.Toastify__toast--error {
  border-left-color: $erreur;

  i {
    color: $erreur;
  }

  .Toastify__progress-bar {
    //background: #e74c3c;
  }
}
