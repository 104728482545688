.modal-cheque-cadeau {
  margin: 0 auto;
  @media (min-width: 768px) {
    width: 849px;
    max-width: 90%;
  }
  .modal-content {
    margin: 23px 20px;
    .modal-body {
      .text-content {
        font-weight: normal;
        font-size: 14px;
        line-height: 22px;

        @media (min-width: 768px) {
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
        }

        p {
          &:not(:last-child) {
            margin-bottom: 30px;
          }
        }

        .button {
          margin-top: 15px;

          @media (min-width: 768px) {
            margin-top: 55px;
          }

          button {
            font-weight: 600;
            font-size: 15px;
            line-height: 18px;
            padding-top: 10px;
            padding-bottom: 10px;
            width: 100%;

            @media (min-width: 768px) {
              width: 329px;
              padding-top: 15px;
              padding-bottom: 15px;
            }
          }
        }
      }
    }
  }
}
