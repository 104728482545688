@import 'src/_variable';

.step.telephone {
  padding: 0px 0px;
  @media (min-width: 768px) {
    padding: 0px 29px;
  }
  .title {
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 24px;

    @media (min-width: 768px) {
      margin-bottom: 26px;
    }
  }

  .telephone-number {
    font-weight: 500;
    font-size: 24px;
    line-height: 29px;
    margin-bottom: 47px;

    span {
      font-size: 17px;
      line-height: 29px;
    }
  }

  .button {
    span {
      margin-top: 15px;
      margin-bottom: 32px;
      font-size: 12px;
      line-height: 16px;

      @media (min-width: 768px) {
        font-size: 16px;
        line-height: 22px;
      }
    }
    .btn {
      padding: 13px 23px;
      font-size: 14px;
      line-height: 17px;

      @media (min-width: 768px) {
        padding: 13px 28px;
        font-size: 16px;
        line-height: 20px;
      }
    }
  }

  p {
    text-align: center;
    margin-bottom: 16px;
    font-size: 18px;
    line-height: 22px;

    @media (min-width: 768px) {
      font-size: 16px;
      line-height: 20px;
    }

    &.error {
      color: $erreur !important;

      font-size: 13px;

      @media (min-width: 768px) {
        font-size: 12px;
      }
    }
  }

  form {
    max-width: 100%;
    width: 346px;

    .inputs {
      max-width: 100%;
      justify-content: space-between;
      flex-direction: column;

      @media (min-width: 768px) {
        width: 346px;
        flex-direction: row;
      }
      .form-group {
        width: 100%;

        @media (min-width: 768px) {
          width: 149px;
        }

        label {
          font-weight: normal;
          font-size: 11px;
          line-height: 18px;
          color: $bleu-edenred;
          text-align: left;
          width: 100%;
        }

        @media (min-width: 768px) {
          label {
            font-size: 12px;
            line-height: 16px;
          }
        }

        .form-control {
          border: 0px;
          margin-top: 0px;
          padding-top: 0px;
          padding-left: 0px;
          padding-bottom: 8px;
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
          color: $bleu-edenred;
          border-radius: 0px;
          border-bottom: 2px solid $bleu-edenred;
        }

        &.error {
          label {
            color: $erreur;
          }

          input {
            border-color: $erreur;
          }

          span {
            color: $erreur;
            font-weight: normal;
            font-size: 12px;
            line-height: 15px;

            @media (min-width: 768px) {
              font-size: 12px;
              line-height: 16px;
            }
          }
        }
      }
    }
  }
}
