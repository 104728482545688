@import 'src/_variable';

.requests {
  .skeleton {
    .react-loading-skeleton {
      @media (max-width: 768px) {
        height: 160px !important;
      }
    }
  }

  .infos {
    font-weight: normal;
    font-size: 14px;
    margin-bottom: 24px;
    margin-top: 13px;

    @media (min-width: 768px) {
      margin-bottom: 28px;
      margin-top: 0px;
    }
  }

  .questionIcon {
    width: 15px;
    height: 15px;
    margin-left: 8px;
  }

  div.reactTooltipInformation {
    background: #d5f0fe;
    box-shadow: 0px 10px 20px rgba(208, 214, 222, 0.5);
    border-radius: 3px;
    max-width: 250px;
    color: black;
    font-size: 13px;
    line-height: 20px;
    color: #252525;

    @media (min-width: 992px) {
      max-width: 315px;
      font-size: 10px;
      line-height: 12px;
    }

    &.show {
      opacity: 1;
    }

    &::after {
      border-right-color: #d5f0fe;
      border-left-color: #d5f0fe;
      border-top: 10px solid transparent;
      border-bottom: 10px solid transparent;
      right: -10px;
      margin-top: -10px;
      border-left-width: 10px;
    }

    &.place-bottom {
      &::after {
        border-right-color: transparent;
        border-left-color: transparent;
        border-bottom: 10px solid #d5f0fe;
      }
    }
  }

  .rect_info > div {
    padding: 24px 15px !important;

    @media (min-width: 768px) {
      padding: 24px !important;
      padding-top: 8px !important;
    }
  }
  .header-requests {
    display: flex;
    align-items: center;
    justify-content: space-between;

    h3 {
      margin-bottom: 0px;
    }

    @media (min-width: 768px) {
      h3 {
        margin-bottom: 16px;
      }
    }

    .title_page {
      margin-left: 0px !important;
    }

    .btn.contact {
      font-weight: 600;
      text-align: center;
      padding: 8px 5px;
      min-width: 140px;
      font-size: 13px;
      line-height: 17px;

      @media (min-width: 768px) {
        padding: 15px 5px;
        min-width: 209px;
        font-size: 15px;
        line-height: 18px;
      }
    }
  }

  p {
    font-weight: normal;
    font-size: 14px;
    line-height: 150%;
    margin-bottom: 16px;
    min-height: 0px;
  }

  .dataTables_paginate {
    padding-top: 0.755em !important;
  }

  table.requests_table {
    display: block;
    background: white;
    border-radius: 10px;
    border-bottom: none !important;
    border-spacing: 0 16px !important;
    border-collapse: separate;
    @media (min-width: 1100px) {
      display: table;
      border-radius: 0px;
    }

    tbody {
      display: table;
      width: 100%;

      @media (min-width: 768px) {
        padding: 16px;
      }

      @media (min-width: 1100px) {
        display: table-row-group;
        // box-shadow: none;
        padding: 0px;
      }

      tr {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        border: 1px solid $gris_clair;
        margin-bottom: 16px;
        border-radius: 10px;
        padding: 16px;

        @media (min-width: 768px) {
          display: table-row;
          height: 81px;
          padding: 0px;
        }

        td {
          font-weight: bold;
          border: none;
          font-weight: 600 !important;
          font-size: 14px !important;
          line-height: 17px !important;
          padding: 0px;
          padding-bottom: 8px;
          border: none;
          width: 100%;

          @media (min-width: 768px) {
            padding: 0px 5px;
            width: auto;
            border-top: 1px solid $gris_clair;
            border-bottom: 1px solid $gris_clair;

            &:first-child {
              border-left: 1px solid $gris_clair;
              border-top-left-radius: 10px;
              border-bottom-left-radius: 10px;
              padding-left: 18px;
            }

            &:last-child {
              border-right: 1px solid $gris_clair;
              border-top-right-radius: 10px;
              border-bottom-right-radius: 10px;
              padding-right: 18px;
            }
          }

          &.id-td,
          &.date-td {
            width: 50%;
            @media (min-width: 768px) {
              width: auto;
            }
          }

          &.date-td {
            .version-date {
              display: flex;
              align-items: center;
              line-height: 18px;

              img {
                width: 16px;
                height: 16px;
                margin-right: 8px;
              }
            }
          }

          @media (min-width: 1200px) {
            padding: 0px 15px;
          }

          small {
            white-space: break-spaces;
            font-weight: 600;
            font-size: 10px;
            line-height: 150%;
            word-break: break-word;

            @media (min-width: 768px) {
              white-space: nowrap;
            }
          }

          @media (min-width: 768px) {
            &.status-td {
              vertical-align: top !important;
            }
          }

          .status {
            display: flex;
            align-items: center;
            white-space: nowrap;

            @media (min-width: 768px) {
              margin-top: 24px;
            }
            img {
              margin-right: 12px;
            }

            .status-text {
              display: flex;
              flex-direction: column;

              .new {
                font-style: normal;
                font-weight: 600;
                font-size: 10px;
                line-height: 150%;
                color: $new_badge;
                position: relative;
                padding-left: 13px;

                &::before {
                  content: '';
                  position: absolute;
                  top: 50%;
                  left: -5px;
                  height: 8px;
                  width: 8px;
                  transform: translate(50%, -50%);
                  border-radius: 50%;
                  background: #f54f20;
                }
              }
            }
          }

          .id {
            span {
              display: block;
              white-space: nowrap;
              font-weight: 600;
              font-size: 14px;
              line-height: 150%;
            }
          }

          &.title-td {
            order: 1;
            padding-bottom: 0px;

            @media (min-width: 768px) {
              padding-top: 16px;
              padding-bottom: 16px;
            }
          }

          .title {
            font-weight: 600;
            font-size: 14px;
            line-height: 150%;
            display: block;
            word-break: break-word;

            @media (min-width: 768px) {
              font-size: 12px;
              line-height: 125%;
            }

            @media (min-width: 1200px) {
              font-size: 16px;
              line-height: 150%;
            }

            .rejection-reason {
              margin-top: 16px;
              font-size: 12px;
              font-weight: 400;
              line-height: 18px;

              a {
                color: #0d6efd !important;
                text-decoration: underline !important;
              }
            }
          }
        }
      }
    }
  }

  .no-requests {
    position: relative;
    padding-top: 16px;
    svg {
      display: none;
      min-height: 109px;
      height: 109px;

      min-width: fit-content;

      position: absolute;
      top: 16px;

      &:first-child {
        left: 24px;
      }

      &:last-child {
        right: 24px;
      }

      @media (min-width: 350px) {
        display: block;
      }

      @media (min-width: 768px) {
        position: relative;
        min-height: 210px;
        height: 210px;
      }
    }
    .content {
      max-width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;

      @media (min-width: 768px) {
        max-width: 665px;
      }

      p {
        display: block;
        font-weight: 500;
        font-size: 12px;
        line-height: 150%;
        text-align: center;
        color: $placeholder;
        margin-bottom: 0px;
        padding: 0px 32px;
        text-align: center;
        min-height: 109px;

        @media (min-width: 768px) {
          font-size: 14px;
        }

        &.title {
          font-weight: 600;
          font-size: 16px;
          line-height: 150%;
          margin-bottom: 16px;
          max-width: 100%;
          display: flex;
          align-items: center;

          @media (min-width: 350px) {
            max-width: calc(100% - (24px * 2 + 40px + 36px));
          }

          @media (min-width: 768px) {
            font-size: 30px;
          }
        }

        small {
          font-size: 10px;
        }
      }
    }
  }
}
