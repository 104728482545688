@import 'src/_variable';

.step.rdv_pris {
  padding: 0px 0px;
  @media (min-width: 768px) {
    padding: 0px 29px;
  }
  .title {
    font-size: 20px;
    line-height: 24px;

    display: flex;
    flex-direction: column;
    align-items: center;

    svg {
      height: 229px;
      width: 358px;
      margin-bottom: 24px;
    }

    span {
      margin-top: 12px;
      font-weight: normal;
      font-size: 16px;
      line-height: 22px;

      &:last-child {
        margin-bottom: 50px;
        margin-top: 0px;
      }
    }
  }

  .happy-people {
    max-width: 100%;
  }

  .mini-recap {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;

    & > span {
      font-weight: bold;
      font-size: 16px;
      line-height: 20px;
      margin-bottom: 16px;
    }

    .mini_card_rdv {
      max-width: 404px !important;

      .card-icon {
        .icon {
          & > svg {
            width: 32px;
            height: 32px;
          }
        }
      }

      .h5 {
        font-weight: 500;
      }
    }
  }
  .button {
    margin-top: 31px;

    a {
      padding: 13px 23px;

      @media (min-width: 768px) {
        padding: 13px 28px;
      }
    }
  }
}
