@import 'src/_variable';

.modal-subvention {
  .modal-content {
    margin: 23px 20px;
  }
  @media (min-width: 992px) {
    max-width: 1157px;
  }

  h5 {
    min-height: 50px;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    margin-top: 27px;
    margin-bottom: 20px;
  }
  .content {
    padding: 0px 0px 27px 0px;
    p {
      font-weight: normal;
      font-size: 14px;
      line-height: 17px;
      color: $bleu-edenred;
      svg {
        min-width: 24px;
        min-height: 24px;
        margin-right: 8px;
      }
    }

    .btn {
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      text-align: center;
      padding: 8px 30px;
    }
  }

  .subvention-card {
    & > div {
      border: 1px solid #eaeaea;
      border-radius: 10px;
    }
    &:not(:last-child) {
      margin-bottom: 16px;

      @media (min-width: 1200px) {
        margin-bottom: 0px;
      }
    }
  }

  button[type='submit'] {
    max-width: 158px;
    margin: 0 auto;
    padding-top: 12px !important;
    padding-bottom: 12px !important;
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
}
