@import '../../_variable.scss';

.slider-notifications {
  .notification-banner-container {
    height: 100%;
    .notification-banner {
      margin-bottom: 0px;
      width: 100%;

      @media (min-width: 768px) {
        width: 100%;
      }

      .card {
        min-height: 100%;
        .card-body {
          & > .row {
            padding-bottom: 27px;
            width: calc(100% - 16px);

            @media (min-width: 768px) {
              padding-bottom: 18px;
            }
          }
        }
      }
    }
  }
}
