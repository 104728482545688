@import 'src/_variable';

.appointment {
  @media (max-width: 768px) {
    .rect_info > div {
      background: transparent;
      box-shadow: none;
      padding: 0px;

      .rect_info_title {
        color: $item_menu !important;
      }
    }

    .rdv_passes > div {
      background: transparent;
      box-shadow: none;
      padding-left: 0px;
      padding-right: 0px;
    }

    .rect_info_no_rdv .prochain_rdv .content {
      background: white;
      box-shadow: 0px 10px 20px rgba(208, 214, 222, 0.5);
      border-radius: 10px;
      padding: 24px;
    }
  }

  .header-appointment {
    display: flex;
    align-items: center;
    justify-content: space-between;

    h3 {
      margin-bottom: 0px;
    }

    @media (min-width: 768px) {
      h3 {
        margin-bottom: 16px;
      }
    }

    .btn.contact {
      font-weight: 600;
      text-align: center;
      padding: 8px 5px;
      min-width: 140px;
      font-size: 13px;
      line-height: 17px;

      @media (min-width: 768px) {
        padding: 15px 5px;
        min-width: 209px;
        font-size: 15px;
        line-height: 18px;
      }
    }
  }
}

// [type="radio"]:checked,
// [type="radio"]:not(:checked) {
//     position: absolute;
//     left: -9999px;
// }
// [type="radio"]:checked + label,
// [type="radio"]:not(:checked) + label
// {
//     position: relative;
//     padding-left: 28px;
//     cursor: pointer;
//     line-height: 20px;
//     display: inline-block;
//     color: #666;
// }
// [type="radio"]:checked + label:before,
// [type="radio"]:not(:checked) + label:before {
//     content: '';
//     position: absolute;
//     left: 0;
//     top: 0;
//     width: 24px;
//     height: 24px;
//     border: 2.4px solid $bleu-edenred;
//     border-radius: 100%;
//     background: #fff;
// }
// [type="radio"]:checked + label:after,
// [type="radio"]:not(:checked) + label:after {
//     content: '';
//     width: 9.6px;
//     height: 9.6px;
//     background: $bleu-edenred;
//     position: absolute;
//     top: 7px;
//     left: 7px;
//     border-radius: 100%;
//     -webkit-transition: all 0.2s ease;
//     transition: all 0.2s ease;
// }
// [type="radio"]:not(:checked) + label:after {
//     opacity: 0;
//     -webkit-transform: scale(0);
//     transform: scale(0);
// }
// [type="radio"]:checked + label:after {
//     opacity: 1;
//     -webkit-transform: scale(1);
//     transform: scale(1);
// }
