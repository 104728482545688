@import 'src/_variable';

.dashboard .rect_info > div.reviews-widget {
  position: relative;
  overflow: hidden;
  min-height: 357px;
  justify-content: flex-start !important;
  padding-left: 24px !important;
  padding-right: 24px !important;
  @media (min-width: 768px) {
    min-height: none;
  }

  .rect_info_title {
    margin-bottom: 12px !important;
  }

  .content {
    height: calc(100% - 139px) !important;
    margin-bottom: 0px !important;
    overflow-y: hidden;
    margin-top: 22px;

    @media (min-width: 768px) {
      // height: calc(100% - (81px + 36px)) !important;
    }

    .review {
      // border: 1px solid rgba(126, 142, 170, 0.13);
      // box-shadow: 2px 2px 10px rgb(0 0 0 / 9%);
      // border-radius: 10px;
      display: flex !important;
      flex-direction: row;
      align-items: flex-start;
      position: relative;
      overflow: hidden;
      // padding: 13px 15px;
      outline: none;

      &:not(:first-child) {
        margin-top: 16px;
      }

      .header {
        margin-bottom: 8px !important;
        img {
          margin-right: 12px;
          height: 16px;
          width: 16px;
        }

        .status-text {
          display: flex;
          flex-direction: column;
          justify-content: center;
          font-weight: 600 !important;
          font-size: 16px !important;
          line-height: 150% !important;
          margin-bottom: 0px !important;
        }
      }

      .body {
        .title {
          font-style: normal;
          font-weight: 500;
          font-size: 12px;
          line-height: 150%;
        }

        .new {
          font-style: normal;
          font-weight: 600;
          font-size: 10px;
          line-height: 150%;
          color: $new_badge;
          position: relative;
          padding-left: 13px;
          margin-bottom: 4px;

          &::before {
            content: '';
            position: absolute;
            top: 50%;
            left: -5px;
            height: 8px;
            width: 8px;
            transform: translate(50%, -50%);
            border-radius: 50%;
            background: #f54f20;
          }
        }
      }
    }

    .nb-requests {
      font-weight: 600;
      font-size: 14px;
      line-height: 150%;
      color: $bleu-edenred;
      text-align: center;
      padding-top: 16px;
    }
  }
  .button {
    width: 100%;
    display: flex;
    justify-content: center;
    padding-top: 16px;
    border-radius: 10px;

    .btn {
      font-weight: 600 !important;
      font-size: 15px !important;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 52px;
      max-width: 229px !important;
    }
  }
}
