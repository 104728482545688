.card.card-rdv.recap_card_rdv {
  width: 362px;

  .card-text {
    flex-direction: column;
    flex-wrap: wrap;

    .card-icon {
      margin-bottom: 0px !important;

      &.card-date {
        margin-right: 32px;
        width: 159px;
      }

      .icon {
        margin-right: 0px;
        & > svg {
          width: 32px;
          height: 32px;
          margin-right: 12px;

          @media (min-width: 768px) {
            width: 37px;
            height: 37px;
          }
        }
      }

      span {
        font-weight: normal;
        font-size: 10px;
        line-height: 12px;
        display: block;

        @media (min-width: 768px) {
          font-size: 12px;
          line-height: 15px;
        }
      }

      h5 {
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;

        @media (min-width: 768px) {
          font-size: 16px;
          line-height: 20px;
        }
      }

      small {
        font-weight: normal;
        font-size: 10px;
        line-height: 12px;

        @media (min-width: 768px) {
          font-size: 12px;
          line-height: 15px;
        }
      }
    }

    .link-recap {
      width: 100%;
      font-weight: normal;
      font-size: 12px;
      line-height: 16px;
      text-decoration-line: underline;
      margin-top: 12px;
      cursor: pointer;
    }

    & > div {
      flex-wrap: wrap;
      &:not(:last-child) {
        margin-bottom: 32px !important;
      }
    }
  }

  .card-body {
    position: relative;

    .calendar_logo {
      position: absolute;
      bottom: -8px;
      right: -8px;
      width: 138px;
      height: 138px;
      z-index: 0;
      opacity: 0.3;
      path {
        stroke: #7e8eaa;
      }
    }
  }
}
