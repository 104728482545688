@import 'src/_variable';
@import 'node_modules/bootstrap/scss/bootstrap';

@import './Inputs.scss';

a {
  color: $bleu-edenred;
  text-decoration: none;
}

@for $i from 5 through 20 {
  .order-#{$i} {
    order: #{$i} !important;
  }
}

.fs-7 {
  font-size: 0.75rem !important;
}

.fs-8 {
  font-size: 0.5rem !important;
}

.font-weight-500 {
  font-weight: 500 !important;
}

.btn-block {
  width: 100%;
}

textarea.no-resize {
  resize: none !important;
}

.table > :not(:first-child) {
  border-top: none;
}

.modal-backdrop {
  transition: opacity 0s linear;
}
.container {
  @media (min-width: 992px) {
    // overflow:hidden;
    //max-width: calc(100% - 375px);
  }

  @media (min-width: 320px) {
    max-width: 100%;
  }
}
.slick-list {
  @media (min-width: 992px) {
    width: 100%;
  }
}
.slick-track {
  display: flex !important;
}

.slick-slide {
  height: inherit !important;

  & > div {
    height: 100%;
  }
}

body.no-cookies-bar {
  #onetrust-consent-sdk {
    display: none !important;
  }
}

.onetrust-pc-dark-filter.ot-fade-in {
  z-index: 1053 !important;
}

#onetrust-banner-sdk.otCenterRounded {
  z-index: 1054 !important;
}

.slider-ec {
  margin-bottom: 16px;
  border-bottom: 0px;

  @media (min-width: 540px) {
    margin-bottom: 18px;
  }

  .slick-list {
    overflow: visible;
    max-width: 100%;
    width: 100%;

    @media (min-width: 540px) {
      overflow: hidden;
      max-width: 100%;
    }
  }

  .slick-slide {
    margin-right: 6px;
  }

  .nav-link {
    height: 74px;
    width: 149px;
    min-width: 149px;
    background: $inactif;
    color: white;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    box-shadow: 0px 10px 20px rgba(208, 214, 222, 0.5);
    border-radius: 10px;
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;

    -webkit-user-select: none; /* Safari 3.1+ */
    -moz-user-select: none; /* Firefox 2+ */
    -ms-user-select: none; /* IE 10+ */
    user-select: none; /* Standard syntax */
    -webkit-user-drag: none;

    @media (min-width: 1200px) {
      height: 77px;
      font-size: 20px;
      line-height: 24px;
    }

    &.active {
      background: $bleu-edenred;
      color: white;
    }
  }
}

.badge {
  height: 16px;
  font-weight: bold;
  font-size: 10px;
  line-height: 100% !important;
}

.border-2 {
  border-width: 2px !important;
}

* {
  font-family: 'Montserrat', sans-serif;
}

.font-weight-600 {
  font-weight: 600 !important;
}

#root {
  box-sizing: border-box;
  height: 100vh;
  background: #f8f9fb;
  overflow-x: hidden;

  .site-content {
    padding: 10px 16px 0px 16px;
    position: relative;

    @media (min-width: 992px) {
      padding: 18px 30px 0px 30px;
    }
  }
}

p {
  min-height: 28px;
}

.cursor-pointer {
  cursor: pointer;
}

.min-height-full {
  min-height: 100vh;
}

.min-height-full-with-navbar {
  min-height: calc(100vh - (64px + 48px));
}

.justify-content-evenly {
  justify-content: space-evenly !important;
}

.icon-presentation-image {
  font-size: 90px !important;
}

.overflow-y-hidden {
  overflow-y: hidden !important;
}

.col-centered {
  float: none;
  margin: 0 auto;
}

.flex-full-centered {
  display: flex;
  justify-content: center;
  align-items: center;
}

.min-h-100 {
  min-height: 100% !important;
}

.min-h-100-with-navBar {
  min-height: calc(100vh - 303px);
  @media (min-width: 768px) {
    min-height: calc(100vh - (64px + 48px));
  }
}

.container-fluid.container-fluid-desktop {
  background: #f8f9fb;
  @media (min-width: 768px) {
    padding-right: 0px;
    padding-left: 0px;
  }
  @media (min-width: 992px) {
    min-height: calc(100vh - 239px); //18 + 90 + 17 + 48 + 66
  }
}

.pointer-events-none {
  pointer-events: none;
}

.container-next-to-sidebar {
  padding-right: 0px !important;
  @media (max-width: 768px) {
    padding: 0 !important;
    width: 100%;
    min-width: 100%;
  }
  @media (min-width: 992px) {
    max-width: calc(100% - 375px);
  }

  @media (min-width: 1440px) {
    max-width: 987px;
  }
}

.loading-screen,
.error-screen {
  h1 {
    font-size: 30px;
    @media (min-width: 768px) {
      font-size: 40px;
    }
  }
}

.stroke-primary {
  path {
    stroke: $bleu_edenred;
  }
}

.stroke-white {
  path {
    stroke: $blanc;
  }
}

.carousel-control-prev,
.carousel-control-next {
  background: red;
}

.title_page {
  font-weight: bold;
  font-size: 24px;
  line-height: 29px;
  color: $bleu_edenred;
  margin-bottom: 16px;

  @media (min-width: 768px) {
    font-size: 30px;
    line-height: 37px;
    margin-top: 36px;
    margin-bottom: 16px !important;
  }
}

.rect_info {
  .text_loader {
    color: $bleu-edenred;
    font-size: 20px;
    line-height: 24px;

    & > div {
      margin-left: 10px;
    }
  }

  &.rect_equipment_carousel {
    > div {
      padding-right: 0px;
      @media (min-width: 768px) {
        padding-right: 15px;

        .slick-slider {
          margin-right: 17px;
        }
      }

      .rect_info_title {
        margin-bottom: 21px;
        text-align: left;

        @media (min-width: 1200px) {
          margin-bottom: 25px;
        }
      }
    }
  }
  > div {
    background: #ffffff;
    box-shadow: 0px 10px 20px rgba(208, 214, 222, 0.5);
    border-radius: 10px;
    padding: 24px 17px;
    text-align: left;

    @media (min-width: 1200px) {
      padding: 30px 32px;
    }

    .rect_info_title {
      font-weight: bold;
      font-size: 18px;
      line-height: 22px;
      color: $bleu-edenred;
      margin-bottom: 13px;

      svg {
        margin-right: 9px;
        width: 24px;
      }

      @media (min-width: 1200px) {
        font-size: 20px;
        line-height: 24px;
        justify-content: center;
        margin-bottom: 30px;

        svg {
          margin-right: 15px;
          width: 29px;
        }
      }
    }

    p {
      font-weight: normal;
      font-size: 14px;
      line-height: 17px;
      min-height: 0px;
      color: $bleu-edenred;

      @media (min-width: 1200px) {
        font-size: 16px;
        line-height: 22px;
        margin-bottom: 32px;
      }
    }
  }
  margin-bottom: 12px;
}

div.reactTooltip {
  background: #d5f0fe;
  box-shadow: 0px 10px 20px rgba(208, 214, 222, 0.5);
  border-radius: 3px;
  max-width: 250px;
  color: black;
  font-size: 13px;
  line-height: 20px;
  color: #252525;

  @media (min-width: 992px) {
    max-width: 315px;
    font-size: 10px;
    line-height: 12px;
  }

  &.show {
    opacity: 1;
  }

  &.place-top {
    &::after {
      border-top-color: #d5f0fe;
    }
  }

  &.place-left {
    &::after {
      border-left-color: #d5f0fe;
    }
  }

  &.place-bottom {
    &::after {
      border-bottom-color: #d5f0fe;
    }
  }

  &.place-right {
    &::after {
      border-right-color: #d5f0fe;
    }
  }
}

.breadcrumb {
  background: transparent;
  padding: 0px;

  > li + li:before {
    font-family: 'Font Awesome 5 Free';
    font-weight: 600;
    content: '\f105' !important;
    font-size: 16px;
  }

  .breadcrumb-item {
    font-weight: normal !important;
    font-size: 12px;
    line-height: 15px;
    color: #323232 !important;

    @media (min-width: 768px) {
      line-height: 16px;
      letter-spacing: -0.24px;
    }

    & a:hover {
      color: $bleu-edenred !important;
    }

    &.active {
      font-weight: bold !important;
      color: $bleu-edenred !important;
    }
  }
}

.btn {
  border-width: 2px;
  &:hover {
    background: white;
    color: $bleu-edenred;
  }

  &.btn-white-border {
    border: 2px solid $bleu-edenred;
    background: white;
    color: $bleu-edenred;

    &:hover {
      background: $bleu-edenred;
      color: white;
    }
  }

  &.disabled,
  &:disabled {
    opacity: 0.3 !important;
  }

  &.hv-text-decoration-underline {
    &:hover {
      text-decoration: underline !important;
    }
  }
}

.badge-ec {
  border-radius: 4px;
  font-weight: bold;
  font-size: 11px;
  line-height: 24px;
  text-align: center;
  color: #ffffff;
  padding: 0px 10px;
}

.logout {
  .btn {
    font-weight: 600;
    font-size: 15px;
    line-height: 18px;
    font-weight: bold;
    padding: 12px 20px;
  }
}

.react-loading-skeleton {
  max-width: 100%;
  border-radius: 10px;
}

.skeleton {
  &.center {
    span:not(.react-loading-skeleton) {
      text-align: center;
    }
  }
  span:not(.react-loading-skeleton) {
    width: 100%;
  }
}

.custom-radio {
  .custom-control-label {
    line-height: 24px !important;
    &::after {
      transition: background-size 0.15s ease-in-out;
      background-size: 0%;
    }

    &::before {
      border-color: $bleu-edenred;
      border-width: 2px;
    }
  }

  .custom-control-input {
    cursor: pointer;
    background-size: 100%;
    width: 24px;
    min-width: 24px;
    height: 24px;
    min-height: 24px;
    color: #fff;
    border-color: $bleu-edenred;
    border-width: 2px;
    background-color: white;
    margin-top: 0px !important;
    margin-right: 0.5rem !important;

    &:focus {
      box-shadow: none;
    }

    &:checked {
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='10' height='10' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23162056'/%3e%3c/svg%3e") !important;
      background-size: 10px;
    }
  }
}

.custom-checkbox {
  // .custom-control-input{
  //   &~.custom-control-label {
  //     line-height: 24px;
  //     &::before {
  //         color: #fff;
  //         border-color: $bleu-edenred;
  //         border-width: 2px;
  //         background-color: white;
  //         top: 50%;
  //         left: -30px;
  //         width: 20px;
  //         height: 20px;
  //         transform: translateY(-50%);
  //     }

  //     &::after {
  //         top: 50%;
  //         left: -30px;
  //         width: 20px;
  //         height: 20px;
  //         transform: translateY(-50%);
  //     }
  //   }

  //   &:checked{
  //     &~.custom-control-label {

  //       &::after {
  //           transition: background-size 0.15s ease-in-out;
  //           background-size: 100%;
  //           background-image:url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E %3Crect width='20' height='20' rx='2' fill='%23162056'/%3E %3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M8.51332 13.7489C7.96551 13.733 7.45703 13.4586 7.15889 13.0239L4.98112 10.0097C4.72926 9.6611 4.80717 9.17397 5.15514 8.92166C5.50311 8.66935 5.98937 8.7474 6.24123 9.09599L8.42981 12.1255C8.45606 12.1638 8.50406 12.1897 8.55854 12.1913C8.61348 12.1929 8.66389 12.1694 8.69309 12.1319L8.76647 12.0503L14.8491 6.17621C15.1584 5.87756 15.6507 5.8866 15.9489 6.19642C16.247 6.50623 16.238 6.99949 15.9287 7.29814L9.87957 13.1398C9.54349 13.5396 9.03944 13.7643 8.51332 13.7489Z' fill='white'/%3E %3C/svg%3E");
  //       }
  //     }
  //   }
  // }

  .custom-control-label {
    line-height: 20px !important;
    &::after {
      transition: background-size 0.15s ease-in-out;
      background-size: 0%;
    }

    &::before {
      border-color: $bleu-edenred;
      border-width: 2px;
    }
  }

  .custom-control-input {
    cursor: pointer;
    background-size: 100%;
    width: 20px;
    min-width: 20px;
    height: 20px;
    min-height: 20px;
    color: #fff;
    border-color: $bleu-edenred;
    border-width: 2px;
    background-color: white;
    margin-right: 0.5rem !important;

    &:focus {
      box-shadow: none;
    }

    &:checked {
      background-size: cover !important;
      background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E %3Crect width='20' height='20' rx='2' fill='%23162056'/%3E %3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M8.51332 13.7489C7.96551 13.733 7.45703 13.4586 7.15889 13.0239L4.98112 10.0097C4.72926 9.6611 4.80717 9.17397 5.15514 8.92166C5.50311 8.66935 5.98937 8.7474 6.24123 9.09599L8.42981 12.1255C8.45606 12.1638 8.50406 12.1897 8.55854 12.1913C8.61348 12.1929 8.66389 12.1694 8.69309 12.1319L8.76647 12.0503L14.8491 6.17621C15.1584 5.87756 15.6507 5.8866 15.9489 6.19642C16.247 6.50623 16.238 6.99949 15.9287 7.29814L9.87957 13.1398C9.54349 13.5396 9.03944 13.7643 8.51332 13.7489Z' fill='white'/%3E %3C/svg%3E");
    }
  }
}

*.no-focus:focus {
  outline: none;
}

div.reactTooltipInformation {
  background: #d5f0fe;
  box-shadow: 0px 10px 20px rgba(208, 214, 222, 0.5);
  border-radius: 3px;
  max-width: 250px;
  color: black;
  font-size: 13px;
  line-height: 20px;
  color: #252525;

  @media (min-width: 992px) {
    max-width: 315px;
    font-size: 10px;
    line-height: 12px;
  }

  &.show {
    opacity: 1;
  }

  &.place-top::after {
    border-top-color: #d5f0fe;
  }

  &.place-left::after {
    border-left-color: #d5f0fe;
  }

  &.place-bottom::after {
    border-bottom-color: #d5f0fe;
  }

  &.place-right::after {
    border-right-color: #d5f0fe;
  }
}

.shadow-ec {
  box-shadow: 0px 10px 30px 0px rgba(208, 214, 222, 0.4) !important;
}
