@import 'src/_variable';

.card-center .card,
.questionFAQ {
  border: none;
  padding: 48px 32px;

  @media (min-width: 540px) {
    width: 500px;
    padding: 48px 46px;
  }

  @media (min-width: 768px) {
    width: 540px;
    padding: 29px 37px;
  }

  .card-body {
    padding: 0px;
    .card-title {
      font-size: 24px;
      line-height: 29px;
      text-align: center;
      color: $bleu_edenred;
      margin-bottom: 8px;

      @media (min-width: 540px) {
        font-size: 32px;
        line-height: 41px;
        margin-bottom: 10px;
      }

      @media (min-width: 768px) {
        font-size: 42px;
        line-height: 51px;
        margin-top: 19px;
        margin-bottom: 10px;
      }
    }

    svg,
    .edenred-logo {
      width: 205px;
      height: 55px;
      margin-bottom: 32px;

      @media (min-width: 540px) {
        width: 285px;
        height: 77px;
        margin-bottom: 50px;
      }
    }

    .card-text {
      font-weight: normal;
      font-size: 14px;
      line-height: 22px;
      text-align: center;
      color: $bleu_edenred;
      margin-bottom: 60px;

      @media (min-width: 540px) {
        font-size: 16px;
        padding: 0px 60px;
        margin-bottom: 36px;
      }
    }

    .buttons {
      .create-account {
        .btn {
          margin-bottom: 17px;
        }
      }

      .login {
        .btn {
          border-width: 2px;
        }
      }

      .btn {
        font-weight: 600;
        font-size: 15px;
        line-height: 18px;
        font-weight: bold;
        padding: 12px 10px;
      }

      @media (min-width: 540px) {
        .btn {
          max-width: 326px;
          margin: 0px auto;
        }
      }

      @media (min-width: 768px) {
        margin-bottom: 33px;
      }
    }
  }
}
