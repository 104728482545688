@import 'src/_variable';

.faq {
  .skeleton {
    .thematic-skeleton {
      span:not(.react-loading-skeleton) {
        width: calc((100% / 2) - 8px);
        margin-right: 8px;
        margin-bottom: 15px;
        border-radius: 10px;

        @media (min-width: 768px) {
          width: calc((100% / 4) - 15px);
          min-width: 200px;
          margin-right: 15px;
          .react-loading-skeleton {
            height: 171px !important;
          }
        }
      }
    }
  }

  .header-faq {
    display: flex;
    justify-content: space-between;

    h3 {
      margin-bottom: 0px;
    }

    @media (min-width: 768px) {
      h3 {
        margin-bottom: 16px;
      }
    }

    .btn.contact {
      font-weight: 600;
      text-align: center;
      padding: 8px 5px;
      min-width: 140px;
      font-size: 13px;
      line-height: 17px;

      @media (min-width: 768px) {
        padding: 15px 5px;
        min-width: 209px;
        font-size: 15px;
        line-height: 18px;
      }
    }
  }

  .featured_item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    padding: 16px;
    border-radius: 10px;
    background: #f2f2f2;
    margin-bottom: 24px;

    @media (min-width: 768px) {
      flex-direction: row;
      padding: 30px 38px;
      margin-bottom: 50px;
    }

    p {
      margin-bottom: 0px;
      font-weight: bold;
      color: $bleu-edenred;
      font-size: 16px;
      line-height: 20px;
      text-align: center;
      margin-bottom: 16px;

      @media (min-width: 768px) {
        font-size: 20px;
        line-height: 24px;
        text-align: left;
        margin-bottom: 0px;
      }
    }

    .read_feature {
      font-weight: 600;
      font-size: 12px;
      line-height: 15px;
      text-align: center;
      padding: 5px;
      min-width: 144px;
    }
  }

  .questions {
    flex-direction: column;

    > div {
      margin-bottom: 24px;
    }

    .content {
      display: flex;
      flex-direction: column;
      margin-bottom: 12px;
      a {
        font-size: 14px;
        line-height: 22px;
        font-weight: normal;
        display: flex;
        margin-bottom: 13px;

        &:hover {
          text-decoration: none;
          color: $bleu-edenred;
        }

        svg {
          width: 30px;
          min-width: 30px;
          height: 30px;
          margin-right: 24px;
        }
      }
    }

    @media (min-width: 768px) {
      flex-direction: row;

      .content {
        a {
          font-weight: 500;
          font-size: 13px;
          line-height: 16px;
          margin-bottom: 24px;
          svg {
            width: 35px;
            min-width: 35px;
            height: 35px;
            margin-right: 21px;
          }
        }
      }
    }
  }

  .thematic {
    .thematic-items {
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;

      .thematic-item {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 118px;
        width: calc((100% / 2) - 8px);
        border: 1px solid #eaeaea;
        border-radius: 10px;
        font-weight: bold;
        font-size: 14px;
        line-height: 17px;
        color: $bleu-edenred;
        margin-bottom: 15px;
        transition: all 0.3s;
        text-decoration: none;
        cursor: pointer;
        margin-right: 8px;
        text-align: center;
        padding: 0 10px;

        &:hover {
          background: $bleu-edenred;
          color: white;
          box-shadow: 0px 10px 20px rgba(208, 214, 222, 0.5);
        }

        @media (min-width: 768px) {
          width: calc((100% / 4) - 15px);
          height: 171px;
          font-size: 20px;
          line-height: 24px;
          min-width: 200px;
          margin-right: 15px;
        }
      }
    }
  }

  .content {
    font-weight: 500;
    font-size: 10px;
    line-height: 12px;
    margin-bottom: 18px;

    @media (min-width: 768px) {
      margin-bottom: 53px;
    }

    p {
      font-weight: 500;
      font-size: 10px;
      line-height: 12px;

      @media (min-width: 768px) {
        font-size: 13px;
        line-height: 16px;
      }

      &:last-child {
        margin-bottom: 0px;
      }

      svg {
        width: 21px;
        min-width: 21px;
        height: 21px;
        min-height: 21px;
        margin-right: 9px;

        @media (min-width: 768px) {
          width: 35px;
          min-width: 35px;
          height: 35px;
          min-height: 35px;
          margin-right: 21px;
        }

        path {
          stroke-width: 1.5px;
        }
      }
    }
  }

  .contact {
    h5 {
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      color: $bleu-edenred;
      margin-top: 35px;

      @media (min-width: 1200px) {
        font-weight: bold;
        font-size: 20px;
        line-height: 24px;
        margin-bottom: 15px;
        margin-top: 60px;
      }
    }

    .btn {
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      text-align: center;
      padding: 8px 15px;

      @media (min-width: 1200px) {
        font-weight: 600;
        font-size: 15px;
        line-height: 18px;
        padding: 15px 44px;
      }
    }
    padding-bottom: 20px;

    @media (min-width: 1200px) {
      padding-bottom: 8px;
    }
  }

  .breadcrumb {
    margin-bottom: 29px;
  }

  .return {
    font-weight: bold;
    font-size: 13px;
    line-height: 16px;
    color: $bleu-edenred;
    margin-bottom: 24px;
    display: inline-block;

    @media (min-width: 768px) {
      margin-bottom: 50px;
    }
  }
}
