@import 'src/_variable';

.footerConnexion {
  display: flex;
  flex-direction: column;
  height: 589px;
  @media (min-width: 768px) {
    height: 160px;
  }
  .footer {
    height: 373px;
    width: 100%;
    background: $bleu-edenred;
    // margin-bottom: 60px;
    // margin-top: 60px;
    margin-left: 17px;
    position: relative;

    &:after,
    &:before {
      position: absolute;
      content: '';
      top: 0px;
      width: 100%;
      height: 100%;
      background: $bleu-edenred;
    }

    &:after {
      right: 100%;
    }

    &:before {
      left: 100%;
    }

    @media (min-width: 540px) {
      text-align: center;
      margin-left: 0px;
    }

    @media (min-width: 768px) {
      height: 123px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    @media (min-width: 992px) {
      margin-bottom: 0px;
      margin-top: 48px;
    }

    .logo_edenred_white {
      width: 225px;
      margin-top: 32px;
      margin-left: -10px;

      @media (min-width: 768px) {
        width: 134px;
        margin-top: 0px;
        margin-left: 0px;
      }

      @media (min-width: 992px) {
        margin-left: 50px;
        width: 193px;
      }

      @media (min-width: 1440px) {
        margin-left: 245px;
      }
    }

    .links {
      font-weight: bold;
      font-size: 14px;
      line-height: 16px;
      margin-top: 32px;

      a {
        display: block;
      }

      .link {
        color: #ffffff;
        text-decoration: none;
        &:not(:last-child) {
          margin-bottom: 24px;
        }
      }

      @media (min-width: 768px) {
        display: flex;
        margin-top: 0px;
        font-size: 12px;
        line-height: 16px;

        .link:not(:last-child) {
          margin-bottom: 0px;
          margin-right: 25px;
        }
      }

      @media (min-width: 992px) {
        .link:not(:last-child) {
          margin-right: 56px;
        }
      }
    }

    .socials {
      margin-top: 32px;

      svg {
        height: 26px;
      }

      .facebook {
        margin-right: 39px;
      }

      @media (min-width: 768px) {
        margin-top: 0px;
        svg {
          height: 16px;
        }

        .facebook {
          margin-right: 24px;
        }
      }

      @media (min-width: 992px) {
        margin-right: 117px;
      }

      @media (min-width: 1440px) {
        margin-right: 220px;
      }
    }
  }

  .bottomBar {
    height: 216px;
    background: $bleu_fonce_edenred;
    position: relative;
    display: flex;
    flex-direction: column;
    margin-left: 17px;
    padding-top: 24px;

    @media (min-width: 540px) {
      align-items: center;
      margin-left: 0px;
    }

    @media (min-width: 768px) {
      height: 37px;
      flex-direction: row;
      justify-content: center;
      padding-top: 0px;
    }

    &:after,
    &:before {
      position: absolute;
      content: '';
      top: 0px;
      width: 100%;
      height: 100%;
      background: $bleu_fonce_edenred;
    }

    &:after {
      right: 100%;
    }

    &:before {
      left: 100%;
    }

    a {
      color: #ffffff;
      font-weight: normal;
      font-size: 12px;
      line-height: 16px;

      &:not(:last-child) {
        margin-bottom: 16px;
      }

      @media (min-width: 768px) {
        &:not(:last-child) {
          margin-bottom: 0px;
          margin-right: 13px;
        }
      }
    }

    .cookie_onetrust {
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}
