@import 'src/_variable';

.container-difficulty-to-connect {
  .card-center {
    min-height: 0px;

    .card-body {
      .card-title {
        margin-bottom: 60px;
        @media (min-width: 768px) {
          font-weight: bold;
          font-size: 32px;
          line-height: 39px;
          margin-bottom: 25px;
          text-align: left;
        }
      }

      .card-text {
        text-align: left;
        margin-bottom: 24px;
        @media (min-width: 768px) {
          padding: 0px;
        }
      }

      .buttons {
        .btn:first-child {
          margin-bottom: 16px;

          @media (min-width: 540px) {
            margin-bottom: 27px;
          }
        }
        .btn {
          font-weight: 600;

          &:not(.btn-primary) {
            color: $bleu-edenred;
          }

          @media (min-width: 540px) {
            max-width: 100%;
          }
        }
      }
    }
  }
}
