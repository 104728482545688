@import 'src/_variable';

.navbar {
  color: white;
  background: $bleu_edenred;
  box-shadow: 0px 4px 10px rgba(208, 214, 222, 0.5);
  border-radius: 10px;
  padding: 12px 13px;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 24px;

  @media (min-width: 768px) {
    background: $blanc;
    color: $bleu_edenred;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
    padding-left: 38px;
    padding-right: 29px;
    height: 90px;
    margin-bottom: 17px;
  }

  .logos {
    color: white;
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
    text-decoration: none;
    position: relative;

    .environment {
      position: absolute;
      bottom: 5%;
      left: 0px;
      transform: translateY(50%);
      text-transform: uppercase;
      font-size: 20px;
      font-weight: 800;
      color: transparent;
      -webkit-text-stroke-width: 2px;
      color: $bleu-edenred;
      opacity: 0.5;
      z-index: 0;
      letter-spacing: 4px;

      &.local {
        color: $validation;
      }

      &.recette {
        color: $erreur;
      }

      &.pre-prod {
        color: $new_badge;
      }
    }

    p {
      font-weight: bold;
      font-size: 14px;
      line-height: 17px;
      min-height: 0px;
      padding: 0px;
      z-index: 1;
    }

    .logo_edenred_white {
      width: 107px;
      margin-left: -5px;
      margin-top: 5px;
      z-index: 1;
    }

    @media (min-width: 768px) {
      color: $bleu_edenred;
      flex-direction: row;
      margin-bottom: 0px;
      align-items: center;

      p {
        font-size: 20px;
        line-height: 24px;
        margin-right: 7px;
        margin-top: -4px;
      }

      svg,
      .logo_edenred {
        width: 179px;
        height: 48px;
        z-index: 1;
      }
    }
  }

  .infos {
    height: 33px;

    @media (min-width: 768px) {
      height: 50px;

      .child-collapse > div {
        height: 100%;
        width: 100%;
        justify-content: flex-end;

        .user,
        .deconnexion {
          small {
            padding-top: 5px;
          }
        }
      }
    }

    .child-collapse {
      height: 100%;
      @media (min-width: 768px) {
        width: 100%;
      }
    }

    .user,
    .deconnexion {
      order: 1;

      svg {
        path {
          stroke-width: 2px;
        }
      }

      small {
        display: none;
      }
    }

    .user {
      padding-right: 22px;
      margin-right: 0px !important;
      position: relative;

      @media (min-width: 768px) {
        padding-right: 0px;
      }

      &::before {
        position: absolute;
        content: '';
        background-color: rgba(255, 255, 255, 0.3);
        width: 2px;
        height: 33px;
        top: 50%;
        right: 0px;
        transform: translateY(-50%);
      }
    }

    .deconnexion {
      padding-left: 24px;
      padding-right: 16px;
    }

    @media (min-width: 768px) {
      svg {
        path {
          stroke: $bleu_edenred;
        }
      }

      p {
        min-height: 0px;
      }

      .info_client {
        text-align: left;
        font-weight: bold;
        font-size: 15px;
        line-height: 18px;
        margin-right: 0px !important;
        padding-right: 55px;
        max-width: 80%;
        width: auto;
        text-overflow: ellipsis;
        white-space: nowrap;

        @media (min-width: 768px) {
          padding-right: 35px;
          // max-width: calc(100% - 700px);
        }

        @media (min-width: 768px) {
          max-width: 165px;
        }

        @media (min-width: 992px) {
          max-width: 250px;
        }

        @media (min-width: 1200px) {
          max-width: 450px;
        }

        small {
          font-weight: 500;
          font-size: 13px;
          line-height: 16px;
          color: $placeholder;
        }

        p {
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

      .user {
        position: relative;
        padding-left: 0px;
        max-width: 115px;
        width: 80px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;

        @media (min-width: 992px) {
          width: 115px;
        }

        small {
          text-overflow: ellipsis;
          overflow: hidden;
          width: 100%;
        }

        &::after,
        &::before {
          position: absolute;
          content: '';
          background-color: $gris_clair;
          width: 2px;
          height: 33px;
          top: 50%;
          transform: translateY(-50%);
        }

        &::after {
          left: 0px;
        }

        &::before {
          right: 0px;
        }
      }
    }

    @media (min-width: 992px) {
      .user,
      .deconnexion {
        align-items: center;

        small {
          display: block;
          font-weight: 500;
          font-size: 13px;
          line-height: 16px;
        }
      }

      .user {
        padding-left: 4px;
        padding-right: 4px;
        margin-right: 0px !important;
      }

      .deconnexion {
        padding-left: 41px;
      }
    }
  }

  .info_client.info_client_mobile {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding-top: 12px;
    border-top: 2px solid rgba(255, 255, 255, 0.3);

    p {
      font-weight: bold;
      font-size: 14px;
      line-height: 17px;
      min-height: 0px;
      word-break: break-all;
      padding-right: 10px;
    }

    small {
      font-weight: 500;
      font-size: 12px;
      line-height: 15px;
    }
  }
}

.navbar-btn-full-mobile {
  .navbar-collapse {
    a {
      width: 100%;

      .btn {
        width: 100%;
      }
      @media (min-width: 768px) {
        width: auto;

        .btn {
          width: auto;
        }
      }
    }
  }
}
