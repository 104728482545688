.swiper-container {
  position: relative;

  &.swiper-container--with-navigation {
    margin: 0 auto;
  }
}

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-container--with-navigation {
  display: flex;
  align-items: center;

  &:has(> .swiper-navigation-disabled) .arrow {
    display: none;
  }
}

.swiper-slide {
  font-size: 18px;
  background: #fff;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: flex-start;

  & > * {
    width: 100%;
  }
}

.swiper-button-lock {
  display: none !important;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.swiper-pagination-bullet {
  position: relative;
  display: inline-block;
  width: 8px;
  height: 8px;
  margin: 0 5px !important;
  border-radius: 5px;
  padding: 0;
  background: #162056;
  cursor: pointer;

  transition: width 0.24s;

  &.swiper-pagination-bullet-active {
    width: 22px;
  }
}
