.loader {
  width: 70px;
  height: 70px;
  max-width: 80%;
  max-height: 80%;

  svg path,
  svg rect {
    fill: #162056;
  }
}
