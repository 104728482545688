@import 'src/_variable';

#enter-contact-email {
  .card-center {
    min-height: 0px;

    .card-body {
      .card-title {
        margin-bottom: 32px;

        @media (min-width: 768px) {
          font-weight: bold;
          font-size: 32px;
          line-height: 39px;
        }
      }

      &.reinitialisation {
        .card-title {
          @media (min-width: 768px) {
            text-align: left;
          }
        }
      }

      &.finish {
        p {
          margin-bottom: 25px;
        }
      }

      img {
        margin-bottom: 32px;

        @media (min-width: 768px) {
          width: 424px;
        }
      }

      p {
        padding-left: 0px;
        padding-right: 0px;
      }

      .card-text {
        margin-bottom: 16px;

        @media (min-width: 768px) {
          padding-left: 10px;
          padding-right: 10px;
        }
      }

      form:not(.Password) {
        @media (min-width: 540px) {
          margin: 0px;
          width: 100%;

          .form-group {
            margin-left: 10px !important;
          }
        }
      }

      .form-group {
        &.input-email {
          margin: 0 auto;

          @media (min-width: 540px) {
            max-width: 350px;
          }

          label {
            font-weight: normal;
            font-size: 12px;
            line-height: 16px;
            margin: 0 auto;
            width: 100%;
            text-align: left;
          }

          .input {
            display: flex;
            border-bottom: 2px solid $bleu-edenred;
            align-items: center;
            margin: 0 auto;
            margin-bottom: 32px;

            &.error {
              border-color: $erreur;
            }

            svg {
              width: 24px;
              height: 24px;
              margin-bottom: 0px;
              margin-left: 6px;
            }

            input {
              padding-left: 6px;
              padding-bottom: 10px;
              font-weight: 500;
              font-size: 14px;
              line-height: 24px;
              color: $bleu-edenred;
              border: 0px;

              &:focus {
                outline: none;
                box-shadow: none;
              }
            }
          }
        }
      }

      .btn {
        padding: 17px 10px;
        font-size: 15px;
        line-height: 18px;
        font-weight: 600;

        @media (min-width: 768px) {
          max-width: 258px;
          margin: 0 auto;
          font-weight: 600;
          font-size: 15px;
          line-height: 18px;
        }

        &.modif-mail {
          margin-left: 0px;
          border-width: 2px !important;
          font-weight: 600;
          font-size: 15px;
          line-height: 18px;
          margin-bottom: 16px;
          padding-top: 15px;
          padding-bottom: 15px;

          @media (min-width: 768px) {
            margin-left: 10px;
          }
        }

        &:not(.loading-button) svg {
          margin-bottom: 0px !important;
          height: 30px !important;
          max-width: 100% !important;
        }
      }

      p.email {
        margin-left: 0px;
        font-size: 15px;
        line-height: 18px;

        @media (min-width: 768px) {
          margin-left: 10px;
        }
      }
    }
  }
}
