@import 'src/_variable';

.firstConnect {
  h4 {
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    color: $bleu-edenred;
    margin-bottom: 15px;

    @media (min-width: 768px) {
      font-size: 30px;
      line-height: 37px;
      margin-top: 41px;
      margin-bottom: 32px;
    }
  }

  p {
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    color: $bleu-edenred;
    margin-bottom: 16px;

    @media (min-width: 768px) {
      font-size: 16px;
      line-height: 22px;
    }
  }

  .obligation {
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    margin-bottom: 24px;
  }

  .conditions-generales {
    p {
      font-weight: normal;
      font-size: 12px;
      line-height: 16px;
      margin-bottom: 24px;

      span:first-child {
        display: block;
        margin-bottom: 15px;
      }
    }

    label {
      font-weight: 600 !important;
      font-size: 14px !important;
      line-height: 16px !important;
    }

    .file-download-icon {
      margin-left: 3px;
      height: 12px;
      width: 12px;
    }
  }

  button {
    font-weight: 600;
    font-size: 15px;
    line-height: 18px;
    padding: 12px 0px;
    margin: 0 auto;
    max-width: 312px;
    margin-top: 19px;
    margin-bottom: 28px;

    &[type='submit'] {
      width: 363px !important;
      max-width: 100% !important;
      margin-bottom: 0px !important;
    }

    @media (min-width: 768px) {
      max-width: 292px;
      margin-top: 46px;
    }
  }

  .informations_contact_fonction_type_contact,
  .preference-contact {
    display: flex;
    align-items: flex-start;

    .form-group.fonction-select {
      margin-bottom: 1rem;
    }

    .form-group.type-contact {
      & > label {
        margin-bottom: 17px;
      }
    }

    .form-inline {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      flex-direction: column;
      width: 100%;

      @media (min-width: 768px) {
        flex-direction: row;
      }

      .form-check {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin: 0px;

        &:not(:last-child) {
          margin-bottom: 11px;
        }

        input {
          width: 20px;
          height: 20px;
          margin-right: 0px;
          margin-left: 0px;
        }

        label {
          margin-left: 0px;
          font-weight: 600;
          font-size: 14px;
          line-height: 16px;
          letter-spacing: -0.3px;
          color: $bleu-edenred;
          margin-bottom: 0px;

          @media (min-width: 768px) {
            margin-left: 10px;
          }
        }
      }
    }
  }

  .preference-contact {
    display: block;

    .form-inline {
      margin-top: 15px;
      flex-direction: row;
      .form-check {
        width: auto;
        &:first-child {
          margin-right: 30px;
        }
      }
    }
  }

  .form-check label {
    margin-bottom: 0px;
  }

  h5:not(:first-child) {
    margin-top: 46px;
  }

  .form-group {
    & > label {
      margin-bottom: 2px;

      .questionIcon {
        width: 15px;
        height: 15px;
        margin-left: 8px;
      }

      div.reactTooltipInformation {
        background: #d5f0fe;
        box-shadow: 0px 10px 20px rgba(208, 214, 222, 0.5);
        border-radius: 3px;
        max-width: 250px;
        color: black;
        font-size: 13px;
        line-height: 20px;
        color: #252525;

        @media (min-width: 992px) {
          max-width: 315px;
          font-size: 10px;
          line-height: 12px;
        }

        &.show {
          opacity: 1;
        }

        &::after {
          border-right-color: #d5f0fe;
          border-left-color: #d5f0fe;
          border-top: 10px solid transparent;
          border-bottom: 10px solid transparent;
          right: -10px;
          margin-top: -10px;
          border-left-width: 10px;
        }
      }
    }

    &:not(:nth-child(2)) {
      padding-right: 15px;
      padding-left: 15px;
    }

    &.elections {
      padding-right: 15px;
      padding-left: 15px;
      margin-top: 1rem;

      @media (min-width: 768px) {
        margin: 0px;
      }
    }

    &.error {
      label {
        color: $erreur;
      }

      input {
        border-color: $erreur;
      }

      span {
        color: $erreur;
        font-weight: normal;
        font-size: 12px;
        line-height: 15px;

        @media (min-width: 768px) {
          font-size: 12px;
          line-height: 16px;
        }
      }
    }

    &.disabled {
      & *:not(.not-disabled) {
        pointer-events: none !important;
      }

      label {
        color: $inactif !important;
      }

      input {
        color: $inactif !important;
        border-color: $inactif !important;

        &::-webkit-input-placeholder {
          color: $inactif !important;
        }
        &:-moz-placeholder {
          color: $inactif !important;
        }
        &::-moz-placeholder {
          color: $inactif !important;
        }
        &:-ms-input-placeholder {
          color: $inactif !important;
        }
      }
    }
  }

  .form-check {
    margin-top: 5px;

    &.disabled {
      opacity: 0.3;
      pointer-events: none;
    }

    input {
      width: 24px;
      min-width: 24px;
      height: 24px;
      min-height: 24px;
      margin-right: 8px;
    }

    label {
      font-weight: normal;
      font-size: 14px;
      line-height: 24px;
    }

    &.custom-control.custom-radio {
      padding-left: 0px;
    }

    &.form-check-identique {
      padding-left: 0px;
      line-height: 24px;
      margin-top: 25px;
      margin-bottom: 24px;
      input {
        width: 20px;
        height: 20px;
        margin-left: 0px;
        margin-top: 1px;
      }

      label {
        margin-left: 30px;
        line-height: 24px;
        font-weight: 600;
        font-size: 14px;
        line-height: 16px;
        letter-spacing: -0.3px;
        color: $bleu-edenred;
      }
    }
  }

  .form-control {
    border: 0px;
    margin-top: 0px;
    padding-top: 0px;
    padding-left: 0px;
    padding-bottom: 8px;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: $bleu-edenred;
    border-radius: 0px;
    border-bottom: 2px solid $bleu-edenred;
  }

  .adressePostale,
  .adressePostaleRDV {
    .title {
      font-weight: bold;
      font-size: 12px;
      line-height: 15px;
      color: $bleu-edenred;
      padding-top: 8px;
      margin-bottom: 6px;
      min-height: 0px;

      @media (min-width: 768px) {
        line-height: 16px;
        margin-bottom: 8px;
        padding-top: 11px;
      }
    }

    .content {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;

      @media (min-width: 768px) {
        flex-direction: row;
        justify-content: space-between;
      }
      .adresse {
        font-weight: normal;
        font-size: 12px;
        line-height: 22px;
        color: $bleu-edenred;
        margin-bottom: 4px;

        @media (min-width: 768px) {
          line-height: 16px;
        }
      }
      button {
        margin-left: 0px;
        margin-right: 0px;
        margin-top: 0px;
        margin-bottom: 0px;
        font-weight: 600;
        font-size: 10px;
        line-height: 12px;
        text-align: center;
        color: $bleu-edenred;
        width: 145px;
        padding-top: 8px;
        padding-bottom: 8px;
        border-width: 2px !important;

        @media (min-width: 768px) {
          font-size: 12px;
          line-height: 15px;
          width: 168px;
        }
      }
    }
  }

  .adressePostaleRDV {
    margin-top: 32px;
  }
}
