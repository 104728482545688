.project .project-content.deploiement {
  .rect_info_title {
    @media (min-width: 768px) {
      margin-bottom: 22px;
    }
  }

  p {
    @media (min-width: 768px) {
      margin-bottom: 22px;
    }
  }
}
