@import 'src/_variable';

.dashboard {
  .slick-slide {
    padding-right: 0px;
  }

  .notification-banner {
    box-shadow: 0px 10px 20px rgb(208 214 222 / 50%);
    .card .card-body {
      & > row {
        width: 100%;
      }
      .content-container {
        margin-right: 0px !important;
        .text {
          flex: 1;

          .text-content {
            margin: 0px;
          }
        }
      }
    }
  }
}

.notification-banner {
  margin-bottom: 24px;

  .card {
    border: none;
    border-left-width: 5px !important;
    border-radius: 5px;
    min-height: 62px;

    &.color-info {
      border-left-color: $start !important;
    }

    &.color-nouveau {
      border-left-color: $new_badge !important;
    }

    .card-body {
      padding: 9.5px 16px;
      display: flex;
      align-items: center;

      .badge {
        font-weight: bold;
        font-size: 10px;
        line-height: 12px;
        text-transform: uppercase;
        margin-right: 20px;
      }
    }

    .content-container {
      align-items: center;
      justify-content: space-between;

      a {
        text-decoration: none;
        align-self: center;
        margin-left: -70px;

        @media (min-width: 768px) {
          align-self: auto;
          margin-left: 0px;
        }
      }
      .btn {
        font-weight: 600;
        font-size: 12px;
        line-height: 15px;
        margin-left: 16px;
      }
    }

    .info-icon {
      width: 30px;
      height: 30px;
      margin-right: 16px;
    }
  }
}
