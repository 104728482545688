@import 'src/_variable';

.MDP {
  width: 70%;
  margin: auto;

  h4 {
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    color: $bleu-edenred;
    margin-bottom: 35px;
  }

  .finish {
    & > div {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    h4 {
      font-weight: bold;
      font-size: 24px;
      line-height: 29px;
      text-align: center;
      color: $bleu-edenred;
      margin-bottom: 32px;

      @media (min-width: 768px) {
        font-size: 30px;
        line-height: 37px;
        margin-bottom: 48px;
      }
    }

    img {
      width: 100%;
      margin-bottom: 33px;
      @media (min-width: 768px) {
        max-width: 424px;
        margin: 0 auto;
        margin-bottom: 24px;
      }
    }

    p {
      font-size: 14px;
      line-height: 22px;
      color: $bleu-edenred;
      margin-bottom: 23px;
      @media (min-width: 768px) {
        font-size: 16px;
        text-align: center;
        max-width: 454px;
        margin-bottom: 32px;
      }
    }

    button {
      width: 100%;
      font-weight: 600;
      font-size: 15px;
      line-height: 18px;
      padding-top: 17px;
      padding-bottom: 17px;
      margin-bottom: 34px;
      @media (min-width: 768px) {
        max-width: 113px;
        padding-top: 15px;
        padding-bottom: 15px;
        margin-bottom: 45px;
      }
    }
  }
}
