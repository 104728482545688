@import '../../../_variable.scss';

.custom-radio {
  display: flex;
  align-items: center;
  input[type='radio'] {
    width: 24px;
    height: 24px;
    cursor: pointer;
    background-size: cover;
    color: $white;
    border-color: $bleu_edenred;
    border-width: 2px;
    background-color: $white;
    margin-top: 0;
    margin-right: 0.5rem !important;
    flex-shrink: 0;
    border-radius: 50%;
    &:focus {
      box-shadow: none;
    }

    &:checked {
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='10' height='10' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23162056'/%3e%3c/svg%3e") !important;
      background-size: 10px;
    }
  }
  label {
    font-size: 14px !important;
    line-height: 24px;
    color: $bleu_edenred;
    font-weight: 400 !important;
    letter-spacing: -0.3px;
    margin-left: 0 !important;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
}

.ec-chip {
  display: inline-block;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  border: 1px solid transparent;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
