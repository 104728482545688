@import 'src/_variable';

.documents-to-sign .no-documents-to-sign {
  svg {
    max-height: 110px;
    @media (min-width: 768px) {
      max-height: 272px;
    }
  }
  p {
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: $placeholder;

    @media (min-width: 768px) {
      font-size: 30px;
      line-height: 37px;
    }

    span {
      display: block;
    }
  }
}
