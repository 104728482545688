.modal_crediter_cheque_cadeau {
  .modal-content {
    border-radius: 20px;
    padding-bottom: 13px;
    .modal-body {
      padding: 0 16px;
      .input {
        position: relative;
        display: inline;
        .input-icon {
          position: absolute;
          display: block;
          top: 50%;
          right: 10px;
          left: auto;
          transform: translateY(-50%);
          pointer-events: none;
          margin-right: 5px;
          font-weight: 600;
        }

        input {
          max-width: 120px;
          height: 36px;
          border-radius: 10px;
          border: 1px solid #333333;
          padding: 5px 20px 5px 10px;

          &::-webkit-outer-spin-button,
          &::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
          }

          &[type='number'] {
            -moz-appearance: textfield;
          }
        }
      }

      .paiement-phase {
        .amounts-entries {
          background-color: #f1f5f9;
          border-radius: 20px;
          margin-bottom: 32px;

          .payement-method-description {
            ol {
              padding: 12px 15px;
            }
          }
        }
        @media (max-width: 768px) {
          .amounts-entries {
            padding: 15px;
          }
        }
      }

      .buttons {
        gap: 12px;
        text-align: end;

        #btn-crediter {
          margin-left: 10px;
        }
      }
    }
  }
}
