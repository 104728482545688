@import 'src/_variable';

.form-group {
  & > label {
    margin-bottom: 2px;
    font-weight: normal;
    font-size: 11px;
    line-height: 18px;
    color: $bleu-edenred;

    .questionIcon {
      width: 15px;
      height: 15px;
      margin-left: 8px;
    }

    div.reactTooltipInformation {
      background: #d5f0fe;
      box-shadow: 0px 10px 20px rgba(208, 214, 222, 0.5);
      border-radius: 3px;
      max-width: 250px;
      color: black;
      font-size: 13px;
      line-height: 20px;
      color: #252525;

      @media (min-width: 992px) {
        max-width: 315px;
        font-size: 10px;
        line-height: 12px;
      }

      &.show {
        opacity: 1;
      }

      &::after {
        border-right-color: #d5f0fe;
        border-left-color: #d5f0fe;
        border-top: 10px solid transparent;
        border-bottom: 10px solid transparent;
        right: -10px;
        margin-top: -10px;
        border-left-width: 10px;
      }
    }
  }

  @media (min-width: 768px) {
    label {
      font-size: 12px;
      line-height: 16px;
    }

    p {
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 25px;
    }
  }

  &.error {
    label {
      color: $erreur;
    }

    input {
      border-color: $erreur;
    }

    span {
      color: $erreur;
      font-weight: normal;
      font-size: 12px;
      line-height: 15px;

      @media (min-width: 768px) {
        font-size: 12px;
        line-height: 16px;
      }
    }
  }

  &.disabled {
    & *:not(.not-disabled) {
      pointer-events: none !important;
    }

    label {
      color: $inactif !important;
    }

    input {
      color: $inactif !important;
      border-color: $inactif !important;

      &::-webkit-input-placeholder {
        color: $inactif !important;
      }
      &:-moz-placeholder {
        color: $inactif !important;
      }
      &::-moz-placeholder {
        color: $inactif !important;
      }
      &:-ms-input-placeholder {
        color: $inactif !important;
      }
    }
  }

  .form-check {
    margin-top: 5px;

    &.disabled {
      opacity: 0.3;
      pointer-events: none;
    }

    input {
      width: 24px;
      min-width: 24px;
      height: 24px;
      min-height: 24px;
      margin-right: 8px;
    }

    label {
      font-weight: normal;
      font-size: 14px;
      line-height: 24px;
    }

    &.form-check-identique {
      padding-left: 0px;
      line-height: 24px;
      margin-top: 25px;
      margin-bottom: 24px;
    }
  }

  .form-control {
    border: 0px;
    margin-top: 0px;
    padding-top: 0px;
    padding-left: 0px;
    padding-bottom: 8px;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: $bleu-edenred;
    border-radius: 0px;
    border-bottom: 2px solid $bleu-edenred;
  }

  .form-group.fonction-select {
    margin-bottom: 1rem;
  }

  .form-group.type-contact {
    & > label {
      margin-bottom: 17px;
    }
  }

  .form-inline {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    width: 100%;

    @media (min-width: 768px) {
      flex-direction: row;
    }
  }
}
