@import 'src/_variable';

.footerDefault {
  height: 365px;
  width: 100%;
  background: $bleu-edenred;
  margin-bottom: 60px;
  margin-top: 60px;
  margin-left: 0px;
  position: relative;

  @media (min-width: 768px) {
    margin-left: 17px;
  }

  &:after,
  &:before {
    position: absolute;
    content: '';
    top: 0px;
    width: 16px;
    height: 100%;
    background: $bleu-edenred;

    @media (min-width: 768px) {
      width: 30px;
    }
  }

  &:after {
    right: 100%;
  }

  &:before {
    left: 100%;
  }

  @media (min-width: 540px) {
    text-align: center;
    margin-left: 0px;
  }

  @media (min-width: 768px) {
    height: 66px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  @media (min-width: 992px) {
    margin-bottom: 0px;
    margin-top: 48px;
  }

  .logo_edenred_white {
    width: 225px;
    margin-top: 32px;

    @media (min-width: 768px) {
      width: 134px;
      margin-top: 0px;
    }

    @media (min-width: 992px) {
      margin-left: 96px;
    }
  }

  .links {
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    opacity: 0.5;
    margin-top: 32px;

    a {
      display: block;
    }

    .link {
      color: #ffffff;
      text-decoration: none;
      &:not(:last-child) {
        margin-bottom: 24px;
      }
    }

    @media (min-width: 768px) {
      display: flex;
      margin-top: 0px;

      .link:not(:last-child) {
        margin-bottom: 0px;
        margin-right: 25px;
      }
    }
  }

  .socials {
    margin-top: 32px;

    svg {
      height: 26px;
    }

    .facebook {
      margin-right: 39px;
    }

    @media (min-width: 768px) {
      margin-top: 0px;
      svg {
        height: 16px;
      }

      .facebook {
        margin-right: 24px;
      }
    }

    @media (min-width: 992px) {
      margin-right: 157px;
    }
  }
}

.cookie_onetrust {
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}
