@import 'src/_variable';

.modal_opinion_rdv {
  .modal-body h2 {
    margin-bottom: 16px !important;

    @media (min-width: 768px) {
      margin-bottom: 32px !important;
    }
  }

  p {
    font-size: 14px;
    line-height: 150%;

    @media (min-width: 768px) {
      font-size: 18px;
    }
  }

  .emoji-general-box {
    display: flex;
    justify-content: space-between;

    .emoji-general {
      display: flex;
      flex-direction: column;
      align-items: center;
      border-radius: 5px;
      font-weight: normal;
      font-size: 10px;
      line-height: 12px;
      padding: 4px;
      width: 92px;
      //height: 98px;
      margin-bottom: 35px;
      cursor: pointer;
      transition: background-color $slow_animation;

      @media (min-width: 768px) {
        font-size: 16px;
        line-height: 22px;
        margin-bottom: 32px;
        padding: 8px;
      }

      img {
        width: 28px;
        height: 28px;
        margin-bottom: 8px;
        transition: transform $slow_animation;

        @media (min-width: 768px) {
          width: 48px;
          height: 48px;
          margin-bottom: 12px;
        }
      }

      &:not(.checked):hover {
        background: #f1f2f3;
        img {
          transform: scale(1.05);
        }
      }
      &.checked {
        font-weight: 600;
        background: $gris_clair;
      }
    }
  }

  .questions {
    .question {
      display: flex;
      font-weight: normal;
      flex-direction: column;
      align-items: center;
      font-size: 14px;
      line-height: 150%;
      margin-bottom: 16px;

      @media (min-width: 768px) {
        flex-direction: row;
      }

      span {
        width: 100%;
        // min-width: 102px;
        margin-right: 16px;
        display: flex;
        align-items: center;
        word-break: break-word;
      }

      .emojis {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 184px;
        min-width: 184px;

        @media (min-width: 768px) {
          width: 282px;
          min-width: 282px;
        }

        .emoji {
          cursor: pointer;
          padding: 6px;
          border-radius: 5px;
          transition: background-color $slow_animation;
          img {
            width: 24px;
            height: 24px;
            transition: transform $slow_animation;

            @media (min-width: 768px) {
              width: 30px;
              height: 30px;
            }
          }
          &.checked {
            background: $gris_clair;
          }
          &:not(.checked):hover {
            background: #f1f2f3;
            img {
              transform: scale(1.05);
            }
          }
        }
      }
    }
  }

  button[type='submit'] {
    width: 118px !important;
  }
}
