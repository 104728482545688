@import '../../../_variable.scss';

.card-ec {
  width: auto;
  box-shadow: 0px 10px 20px rgb(208 214 222 / 50%);
  border-radius: 10px;

  & .card {
    border: none;
    background-color: transparent;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
  }
}
