@import 'src/_variable';

.step.rendez-vous {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px 0px;
  @media (min-width: 768px) {
    padding: 0px 29px;
  }

  .badge {
    background: $bleu_ciel_card;
    padding: 4px 8px;
    margin-bottom: 12px;
    color: $bleu-edenred;
    height: auto;
    width: auto;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    svg {
      width: 24px;
      height: 24px;
      margin-right: 8px;
    }

    @media (min-width: 768px) {
      font-size: 14px;
      line-height: 17px;
    }
  }

  .title {
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 24px;

    span {
      display: block;
      margin-top: 4px;
      font-weight: normal;
      font-size: 12px;
      line-height: 16px;
    }

    @media (min-width: 768px) {
      margin-bottom: 26px;
    }
  }
}
