@import '../../../_variable.scss';

.EC-TextField {
  border: 0px;
  margin-top: 0px;
  padding-top: 0px;
  padding-left: 0px;
  padding-bottom: 8px;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: $bleu_edenred;
  border-radius: 0px;
  border-bottom: 2px solid $bleu_edenred;
}
