@import '../../../_variable.scss';

.custom-checkbox {
  display: flex;
  align-items: center;
  input[type='checkbox'] {
    width: 24px;
    height: 24px;
    cursor: pointer;
    background-size: cover;
    color: $white;
    border-color: $bleu_edenred;
    border-width: 2px;
    background-color: $white;
    margin-top: 0;
    margin-right: 0.5rem !important;
    flex-shrink: 0;
    &:checked {
      background-size: cover !important;
      background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E %3Crect width='20' height='20' rx='2' fill='%23162056'/%3E %3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M8.51332 13.7489C7.96551 13.733 7.45703 13.4586 7.15889 13.0239L4.98112 10.0097C4.72926 9.6611 4.80717 9.17397 5.15514 8.92166C5.50311 8.66935 5.98937 8.7474 6.24123 9.09599L8.42981 12.1255C8.45606 12.1638 8.50406 12.1897 8.55854 12.1913C8.61348 12.1929 8.66389 12.1694 8.69309 12.1319L8.76647 12.0503L14.8491 6.17621C15.1584 5.87756 15.6507 5.8866 15.9489 6.19642C16.247 6.50623 16.238 6.99949 15.9287 7.29814L9.87957 13.1398C9.54349 13.5396 9.03944 13.7643 8.51332 13.7489Z' fill='white'/%3E %3C/svg%3E");
    }
  }
  label {
    font-size: 14px !important;
    line-height: 24px;
    color: $bleu_edenred;
    font-weight: 600 !important;
    letter-spacing: -0.3px;
    margin-left: 10px !important;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
}

.ec-chip {
  display: inline-block;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  border: 1px solid transparent;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
