@import 'src/_variable';

.informations-generales {
  h5 {
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    color: $bleu-edenred;
    margin-left: 0px;

    &:not(:first-child) {
      margin-top: 48px;
    }

    @media (min-width: 768px) {
      font-size: 16px;
      line-height: 20px;
    }
  }

  .form-group {
    &.type-contact {
      &:not(.type-contact-default) {
        padding-right: 15px !important;
        padding-left: 15px !important;
      }
      margin-bottom: 1rem !important;
      margin-top: 1rem !important;
      flex-direction: column;
      align-items: flex-start;
      @media (min-width: 768px) {
        margin: 0px !important;
        padding: 0px !important;
      }

      p {
        display: flex;
        flex-direction: column;
      }

      .form-inline {
        display: flex;
        flex-wrap: wrap;
      }

      .form-check {
        width: auto;
        @media (min-width: 768px) {
          margin-left: 0 !important;
          margin-right: 10px !important;
          display: flex;
          align-items: center;
        }
      }
    }

    label {
      font-weight: normal;
      font-size: 11px;
      line-height: 18px;
      color: $bleu-edenred;
    }

    p {
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      border-color: $gris_clair !important;
      padding-bottom: 5px;
      padding-left: 0px;
      min-height: 26px;
      word-break: break-all;
    }

    @media (min-width: 768px) {
      label {
        font-size: 12px;
        line-height: 16px;
      }

      p {
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 25px;
      }
    }
  }

  @media (max-width: 768px) {
    .form-inline {
      .form-group {
        padding: 0px;
      }
    }
  }

  @media (min-width: 768px) {
    .form-inline {
      display: flex;
      align-items: flex-end;
      .form-group:not(:nth-child(2)) {
        padding: 0px;
      }
      .form-group:nth-child(2) {
        padding-right: 0px;
        @media (min-width: 768px) {
          padding-right: 15px;
        }
      }
    }

    .address-rdv {
      margin-top: 52px;
    }
  }

  button {
    font-weight: 600;
    font-size: 15px;
    line-height: 18px;
    padding: 12px 0px;
    margin: 0 auto;
    width: 312px;
    max-width: calc(100% - 32px);
    margin-top: 19px;
    margin-bottom: 28px;

    @media (min-width: 768px) {
      width: 292px;
    }
  }

  .informations-generales-inputs {
    .obligation {
      font-weight: normal;
      font-size: 12px;
      line-height: 16px;
      margin-bottom: 24px;
    }

    button {
      font-weight: 600;
      font-size: 15px;
      line-height: 18px;
      padding: 12px 0px;
      margin: 0 auto;
      max-width: calc(100% - 32px);
      margin-top: 19px;
      margin-bottom: 28px;

      @media (min-width: 768px) {
        max-width: 292px;
        margin-top: 46px;
      }
    }

    .informations_contact_fonction_type_contact,
    .preference-contact {
      display: flex;
      align-items: flex-start;
      flex-direction: column;

      @media (min-width: 768px) {
        flex-direction: row;
      }

      .form-group.type-contact {
        & > label {
          margin-bottom: 17px;
        }
      }

      .form-inline {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: column;

        @media (min-width: 768px) {
          flex-direction: row;
        }
        /*
        .form-check {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          margin: 0px;

          &.disabled {
            opacity: 0.3;
            pointer-events: none;
          }

          &:not(:last-child) {
            margin-bottom: 11px;
          }

          input {
            width: 20px;
            height: 20px;
            margin-right: 0px;
          }

          label {
            margin-left: 30px;
            font-weight: 600;
            font-size: 14px;
            line-height: 16px;
            letter-spacing: -0.3px;
            color: $bleu-edenred;

            @media (min-width: 768px) {
              margin-left: 10px;
            }
          }
        }
        */
      }
    }

    .preference-contact {
      display: block;

      .form-inline {
        margin-top: 15px;
        flex-direction: row;
        display: flex;
        flex-wrap: wrap;
        .form-check {
          width: auto;
          @media (min-width: 768px) {
            margin-left: 0 !important;
            margin-right: 10px !important;
            display: flex;
            align-items: center;
          }
          &:first-child {
            margin-right: 30px;
          }
        }
      }
    }

    h5:not(:first-child) {
      margin-top: 46px;
    }

    .form-group {
      & > label {
        margin-bottom: 2px;

        .questionIcon {
          width: 15px;
          height: 15px;
          margin-left: 8px;
        }

        div.reactTooltipInformation {
          background: #d5f0fe;
          box-shadow: 0px 10px 20px rgba(208, 214, 222, 0.5);
          border-radius: 3px;
          max-width: 250px;
          color: black;
          font-size: 13px;
          line-height: 20px;
          color: #252525;

          @media (min-width: 992px) {
            max-width: 315px;
            font-size: 10px;
            line-height: 12px;
          }

          &.show {
            opacity: 1;
          }

          &::after {
            border-right-color: #d5f0fe;
            border-left-color: #d5f0fe;
            border-top: 10px solid transparent;
            border-bottom: 10px solid transparent;
            right: -10px;
            margin-top: -10px;
            border-left-width: 10px;
          }
        }
      }

      &:not(:nth-child(2)) {
        padding-right: 15px;
        padding-left: 15px;
      }

      &.telephone-principal {
        @media (min-width: 768px) {
          margin-top: -24px;
        }
      }

      &.elections {
        padding-right: 15px;
        padding-left: 15px;
        margin-top: 1rem;

        @media (min-width: 768px) {
          margin: 0px;
        }
      }

      &.disabled {
        & *:not(.not-disabled) {
          pointer-events: none !important;
        }

        label {
          color: $inactif !important;
        }

        input {
          color: $inactif !important;
          border-color: $inactif !important;

          &::-webkit-input-placeholder {
            color: $inactif !important;
          }
          &:-moz-placeholder {
            color: $inactif !important;
          }
          &::-moz-placeholder {
            color: $inactif !important;
          }
          &:-ms-input-placeholder {
            color: $inactif !important;
          }
        }
      }
    }

    .error {
      label {
        color: $erreur;
      }

      input {
        border-color: $erreur;
      }

      span {
        color: $erreur;
        font-weight: normal;
        font-size: 12px;
        line-height: 15px;

        @media (min-width: 768px) {
          font-size: 12px;
          line-height: 16px;
        }
      }
    }

    .form-check {
      margin-top: 5px;
      input {
        width: 24px;
        height: 24px;
        margin-right: 8px;
      }

      label {
        font-weight: normal;
        font-size: 14px;
        line-height: 24px;
      }

      /* &.form-check-identique {
        padding-left: 15px;
        line-height: 24px;
        margin-top: 25px;
        margin-bottom: 24px;
        input {
          width: 20px;
          height: 20px;
          margin-left: 0px;
          margin-top: 1px;
        }

        label {
          margin-left: 30px;
          line-height: 24px;
          font-weight: 600;
          font-size: 14px;
          line-height: 16px;
          letter-spacing: -0.3px;
          color: $bleu-edenred;
        }
      }*/
    }

    .form-control {
      border: 0px;
      margin-top: 0px;
      padding-top: 0px;
      padding-left: 0px;
      padding-bottom: 8px;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: $bleu-edenred;
      border-radius: 0px;
      border-bottom: 2px solid $bleu-edenred;
    }
  }
}
