@import 'src/_variable';

.card .card-body .card-creation-mdp {
  h4.card-title {
    margin-bottom: 32px;

    @media (min-width: 768px) {
      font-weight: bold;
      font-size: 32px;
      line-height: 39px;
      margin-top: 0px;
      margin-bottom: 40px;
      text-align: left;
    }
  }
}
