@import 'src/_variable';

.step.recapitulatif {
  padding: 0px 0px;
  @media (min-width: 768px) {
    padding: 0px 29px;
  }
  .title {
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 16px;
  }

  .button {
    span {
      font-size: 16px;
      line-height: 22px;
      margin-top: 25px;
      margin-bottom: 11px;
    }
    .btn {
      padding: 13px 23px;

      @media (min-width: 768px) {
        padding: 13px 28px;
      }
    }
  }
}
