@import 'src/_variable';

.wallets {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  margin-bottom: 120px;

  @media (max-width: 664px) {
    justify-content: space-between;
    margin-bottom: 0;
  }

  .wallet {
    width: 250px;
    min-width: 250px;
    border: 1px solid rgba(126, 142, 170, 0.13);
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.09);
    border-radius: 10px;
    display: flex !important;
    flex-direction: column;
    align-items: flex-start;
    position: relative;
    overflow: hidden;
    padding: 16px;
    outline: none;

    @media (min-width: 768px) {
      width: 100% !important;
    }

    .solde {
      font-weight: bold;
      font-size: 16px;
      line-height: 24px;
      color: #162056;
      margin-bottom: 4px;
    }

    .solde-app {
      font-weight: normal;
      font-size: 12px;
      line-height: 16px;
      color: #162056;
      margin-bottom: 9px;
    }

    .wallet-btn {
      margin-bottom: 0px;
      font-size: 12px;
    }

    svg {
      position: absolute;
      bottom: -11px;
      right: -10px;
      height: 79px;
      width: 70px;

      path {
        stroke-width: 2px;
        stroke: $gris;
      }
    }
  }
}
