@import '../../../_variable.scss';

.form-control-react-select {
  display: block;
  width: 100%;
  min-height: calc(1.5em + 0.75rem + 2px);
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  outline: none;
  &:focus {
    outline: none;
  }
  & > div {
    border: 0px;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    min-height: 24px;
    color: $bleu-edenred;
    border-bottom: 2px solid $bleu-edenred;
    border-radius: 0px;

    div[class*='ValueContainer'] {
      padding-left: 0px;

      div[class*='singleValue'] {
        color: $bleu-edenred;
      }
    }
    div[id*='react-select-2'] {
      min-height: 40px;
    }
    span[class*='indicatorSeparator'] {
      display: none;
    }
    div[class*='indicatorContainer'] {
      svg {
        width: 24px;
        height: 24px;
      }
    }
  }

  &.error {
    & > div {
      border-bottom: 2px solid $erreur;
    }
  }
}
