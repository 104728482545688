.carousel_item {
  width: 213px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  background: #ffffff;
  border: 1px solid #eaeaea;
  border-radius: 10px;
  &:last-child {
    margin-right: 0 !important;
  }

  svg {
    height: 65px;
    width: 65px;
    margin-top: 53px;
    margin-bottom: 23px;
  }

  .images {
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-top: 57px;
    margin-bottom: 34px;
    svg {
      height: 40px;
      width: 40px;
      margin-top: 0px;
      margin-bottom: 0px;
    }

    .add {
      width: 16px;
      height: 16px;
      margin: 0px 16px;
    }
  }

  .title {
    font-weight: bold;
    font-size: 16px;
    color: #162056;
    margin: 0px 15px;
    margin-bottom: 25px;
  }

  .description {
    font-size: 15px;
    line-height: 22px;
    text-align: center;
    color: #162056;
    margin: 0px 15px;
    margin-bottom: 20px;
  }

  .btn {
    margin-bottom: 40px;
    padding: 7px 33px;
  }
}
