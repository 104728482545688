@import 'src/_variable';

.container-termination {
  .info-recap {
    font-weight: 500;
    font-size: 16px;
    line-height: 34px;
    border-width: 2px !important;
    border-color: $bleu-edenred !important;
    padding-bottom: 5px;
    padding-left: 0px;
    min-height: 26px;
    word-break: break-all;
  }
}
