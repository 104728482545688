@import 'src/_variable';

.atelier-cadrage {
  .date-atelier {
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    color: $bleu-edenred;

    @media (min-width: 768px) {
      font-size: 20px;
      line-height: 24px;
    }
  }
}
