@import 'src/_variable';

#mail-not-exist {
  .card-center {
    min-height: 0px;

    .card-body {
      .card-title {
        margin-bottom: 60px;

        @media (min-width: 768px) {
          font-weight: bold;
          font-size: 32px;
          line-height: 39px;
          margin-bottom: 25px;
          text-align: left;
        }
      }

      .card-text {
        text-align: left;
        margin-bottom: 24px;

        @media (min-width: 768px) {
          padding: 0px;
        }
      }

      .buttons {
        a {
          &:first-child {
            .btn {
              margin-bottom: 0px;

              @media (min-width: 540px) {
                margin-bottom: 0px;
              }
            }
          }

          .btn {
            font-weight: 600;

            @media (min-width: 540px) {
              max-width: 100%;
              width: 100%;
            }

            &.btn-block {
              width: 100%;

              @media (min-width: 540px) {
                width: 50%;
              }
            }
          }
        }
      }
    }
  }
}
