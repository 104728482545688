@import 'src/_variable';

.Password {
  label {
    font-size: 12px;
    line-height: 16px;

    &.withToolTip {
      display: flex;
      align-items: center;

      svg {
        width: 15px !important;
        height: 15px !important;
        margin-left: 5px;
      }
    }
  }

  .input-group {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: nowrap;
    height: 35px;
    margin-top: 2px;
    border-color: $inactif !important;
    border-bottom: 2px solid #162056;
    border-radius: 1px;
    padding-left: 0px;

    &:not(.empty) {
      border-color: $bleu-edenred !important;

      input {
        color: $bleu-edenred;
      }
    }

    & > svg {
      width: 24px !important;
      height: 24px !important;
      margin-bottom: 0px !important;
      margin-left: 3px !important;
    }

    input {
      font-weight: 500;
      font-size: 30px;
      line-height: 24px;
      height: 24px;
      color: $inactif;
      box-shadow: none;
      border: none;

      &[type='text'] {
        font-size: 1rem;
      }

      &::-webkit-input-placeholder {
        font-size: 1rem;
      }
      &:-moz-placeholder {
        font-size: 1rem;
      }
      &::-moz-placeholder {
        font-size: 1rem;
      }
      &:-ms-input-placeholder {
        font-size: 1rem;
      }
    }

    .input-group-append {
      .input-group-text {
        background: transparent;
        border: 0px;
        padding-bottom: 0px;
        padding-right: 12px;
        svg {
          width: 24px;
          height: 20px;
          margin-bottom: 0px;

          &.eye-hidden {
            margin-top: -1px;
          }
        }
      }
    }
  }
  .criteriaBox {
    margin-top: 26px;

    @media (min-width: 768px) {
      padding: 20px;
      margin-top: 6px;
    }

    .title {
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      color: $bleu-edenred;
      margin-bottom: 22px;
    }

    .criterias {
      margin-bottom: 41px;
      .form-check {
        display: flex;
        margin-bottom: 10px;
        padding: 0px;

        &.validate {
          label {
            color: $validation;
          }
        }

        svg {
          width: 24px;
          min-width: 24px;
          height: 24px;
          align-items: center;
          margin-bottom: 0px;
          margin-right: 10px;
        }

        label {
          font-size: 14px;
          line-height: 22px;
          color: $inactif;
        }
      }
    }
  }
  button {
    padding: 15px 0px;
    font-weight: 600;
    font-size: 15px;
    line-height: 18px;
    &.disabled {
      background: #d6d6d6;
      border-color: #d6d6d6;
      opacity: 1;
    }

    &.loading {
      padding: 9px 0px !important;
    }

    svg {
      margin-bottom: 0px !important;
      height: 30px !important;
      max-width: 100% !important;
    }
  }
}
