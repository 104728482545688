@import 'src/_variable';

.container-faq-contact {
  .rect_info > div {
    padding-left: 0px;
    padding-right: 0px;
    @media (min-width: 768px) {
      padding: 30px 32px;
    }
  }

  small.demande-solution {
    display: block;
    color: $bleu-edenred;
    font-weight: normal;
    font-size: 13px;
    line-height: 15px;
    margin-bottom: 20px;

    @media (min-width: 768px) {
      line-height: 16px;
      margin-top: -10px;
    }
  }

  small.mandatory {
    display: block;
    color: $bleu-edenred;
    font-weight: normal;
    font-size: 12px;
    line-height: 15px;
    margin-bottom: 32px;

    @media (min-width: 768px) {
      line-height: 16px;
    }
  }

  form {
    label {
      margin-bottom: 0px;

      @media (min-width: 768px) {
        font-size: 12px;
        line-height: 16px;

        &.textareaLabel {
          margin-top: 16px;
          small {
            font-weight: bold;
            font-size: 16px;
            line-height: 20px;
          }
        }
      }
      small {
        font-weight: normal;
        font-size: 11px;
        line-height: 18px;

        @media (min-width: 768px) {
          font-size: 12px;
          line-height: 16px;
        }
      }
    }

    textarea {
      margin-top: 8px;
      padding: 16px;
      font-weight: normal;
      font-size: 14px;
      line-height: 22px;
      border-radius: 10px;

      @media (min-width: 768px) {
        padding: 31px 26px;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
      }
    }
  }

  .send {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 800px;
    margin: 0 auto;
    h4 {
      font-weight: bold;
      font-size: 24px;
      line-height: 29px;
      color: $bleu-edenred;
      margin-bottom: 32px;

      @media (min-width: 768px) {
        font-size: 30px;
        line-height: 37px;
      }
    }

    p {
      font-weight: normal;
      font-size: 14px;
      line-height: 22px;
      color: $bleu-edenred;
      margin-bottom: 24px;

      @media (min-width: 768px) {
        font-size: 16px;
        line-height: 22px;
        max-width: 417px;
        margin-bottom: 32px;
      }
    }

    button {
      width: 100%;
      font-weight: 600;
      font-size: 15px;
      line-height: 18px;
      padding-top: 17px;
      padding-bottom: 17px;

      @media (min-width: 768px) {
        max-width: 117px;
        padding-top: 15px;
        padding-bottom: 15px;
      }
    }
  }

  .header-faq {
    display: flex;
    flex-direction: column;

    .return {
      @media (min-width: 768px) {
        margin: 0;
      }
    }
  }
  .nav {
    display: flex;
    justify-content: space-between;
    margin-bottom: 22px;
    border: none;
    .nav-item {
      width: calc(50% - 8px);
      .nav-link {
        height: 74px;
        width: 100%;
        background: $inactif;
        color: white;
        font-weight: bold;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        box-shadow: 0px 10px 20px rgba(208, 214, 222, 0.5);
        border-radius: 10px;
        font-weight: bold;
        font-size: 14px;
        line-height: 17px;
        text-align: center;

        -webkit-user-select: none; /* Safari 3.1+ */
        -moz-user-select: none; /* Firefox 2+ */
        -ms-user-select: none; /* IE 10+ */
        user-select: none; /* Standard syntax */
        -webkit-user-drag: none;

        @media (min-width: 1200px) {
          height: 77px;
          font-size: 20px;
          line-height: 24px;
        }

        &.active {
          background: $bleu-edenred;
          color: white;
        }
      }
    }
  }

  .custom-file-content {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 50px;
    .custom-file {
      margin-bottom: 6px;
      .custom-file-input {
        display: none;
      }
      .custom-file-label {
        border: none;
        border-bottom: 2px solid $bleu-edenred;
        border-radius: 0px;
        color: $bleu-edenred;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        padding-left: 0px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 100%;
        height: calc(1.5em + 0.75rem + 2px);
        line-height: calc(1.5em + 0.75rem + 2px);
        &::after {
          display: none;
        }

        svg {
          margin-right: 12px;
          width: 24px;
          height: 24px;
        }
      }
    }
    small {
      width: 100%;
      font-weight: normal;
      font-size: 11px;
      line-height: 18px;

      @media (min-width: 768px) {
        font-weight: normal;
        font-size: 12px;
        line-height: 17px;
      }
    }

    @media (min-width: 768px) {
      align-items: flex-end;
      margin-bottom: 32px;
      button {
        margin-left: 24px;
        margin-bottom: 6px;
        font-weight: 600;
        font-size: 15px;
        line-height: 18px;
        text-align: center;
        padding: 0px 12px;
        border-width: 2px !important;
        height: 34px;
      }
    }
  }

  button.send {
    width: calc(100% - 30px);
    margin-left: 15px;
    font-weight: 600;
    font-size: 15px;
    line-height: 18px;
    text-align: center;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-bottom: 36px;

    @media (min-width: 768px) {
      max-width: 301px;
      padding-top: 15px;
      padding-bottom: 15px;
      margin-bottom: 23px;
    }
  }

  .files {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin-top: 10px;

    .file {
      background: white;
      border: 2px solid $bleu-edenred;
      padding: 10px;
      margin-bottom: 10px;
      border-radius: 10px;
      color: $bleu-edenred;
      overflow: hidden;
      white-space: nowrap;
      max-width: 32%;
      width: 32%;
      margin-right: 1.3%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      span {
        width: 80%;
        overflow: hidden;
      }
    }
  }

  .contacts {
    display: flex;
    flex-direction: column;

    @media (min-width: 1440px) {
      flex-direction: row;
    }

    .contact {
      width: 100%;
      padding: 42px 37px;
      border: 2px solid #eaeaea;
      border-radius: 10px;
      margin-bottom: 20px;

      @media (min-width: 1440px) {
        // max-width: 279px;
        margin-bottom: 0px;

        &:not(:last-child) {
          margin-right: 28px;
        }
      }
      .title {
        font-weight: bold;
        font-size: 18px;
        line-height: 22px;
        color: $bleu-edenred;
        margin-bottom: 10px;

        @media (min-width: 768px) {
          font-size: 20px;
          line-height: 24px;
        }
      }
      p {
        font-weight: normal;
        font-size: 16px;
        line-height: 22px;
        color: $bleu-edenred;
        margin-bottom: 10px;
      }
      span {
        font-weight: bold;
        font-size: 18px;
        line-height: 22px;
        color: $bleu-edenred;

        @media (min-width: 768px) {
          font-size: 20px;
          line-height: 24px;
        }
      }
    }
  }
}
