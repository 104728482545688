@import 'src/_variable';

.preparation {
  & > p {
    margin-bottom: 0px;
  }

  ul.timeline {
    list-style-type: none;
    position: relative;
    padding-left: 0px;

    & > li {
      margin: 20px 0;
      margin-bottom: 32px;
      padding-left: calc(35px + 16px);
      position: relative;

      @media (min-width: 768px) {
        margin: 20px 0;
        padding-top: 13px;
        padding-left: 75px;
        padding-bottom: 30px;
      }

      &:after {
        content: ' ';
        background: $info;
        display: inline-block;
        position: absolute;
        left: 13px;
        top: 50px;
        height: calc(100% - 50px);
        border-radius: 3px;
        width: 4px;

        @media (min-width: 768px) {
          left: 20px;
          top: 68px;
          height: calc(100% - 68px);
          width: 6px;
        }
      }

      @media (min-width: 768px) {
        &:last-child:after {
          display: none;
        }
      }

      span.number {
        background: white;
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        border-radius: 50%;
        border: 3px solid $info;
        top: 0px;
        left: -2px;
        width: 35px;
        height: 35px;
        font-weight: bold;
        font-size: 20px;
        line-height: 24px;

        @media (min-width: 768px) {
          width: 50px;
          height: 50px;
        }
      }

      .title {
        font-weight: bold;
        font-size: 18px;
        line-height: 22px;
        color: $bleu-edenred;
        margin-bottom: 19px;

        @media (min-width: 768px) {
          font-size: 20px;
          line-height: 24px;
        }
      }

      p {
        margin-bottom: 15px;
        max-width: 800px;

        @media (min-width: 768px) {
          font-size: 14px;
          line-height: 22px;
        }
      }

      .action {
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        padding-top: 8px;
        padding-bottom: 8px;
        min-width: 100%;

        @media (min-width: 768px) {
          min-width: 275px;
          font-size: 15px;
          line-height: 18px;
          padding: 15px 45px;
        }
      }

      .form-group,
      .form-domain-checker {
        display: flex;
        justify-content: flex-start;
        align-items: stretch;
        flex-direction: column;
        flex-wrap: wrap;

        @media (min-width: 768px) {
          flex-direction: row;
          align-items: flex-end;
        }

        & > div {
          flex: 1;
          margin-right: 12px;
          max-width: 350px;
          min-width: 200px;

          @media (min-width: 768px) {
            max-width: 100%;
            min-width: 100%;
            margin-bottom: 15px;
          }

          @media (min-width: 1200px) {
            max-width: 350px;
            min-width: 200px;
            margin-bottom: 0px;
          }

          label {
            font-weight: normal;
            font-size: 12px;
            line-height: 16px;
            margin-bottom: 0px;
            color: $bleu-edenred;
          }

          input {
            font-style: italic;
            font-weight: 500;
            font-size: 14px;
            line-height: 22px;
            padding-left: 0px;
            padding-bottom: 10px;
            margin-bottom: 16px;
            border: 0px;
            border-radius: 0px;
            border-bottom: 2px solid $inactif;

            @media (min-width: 768px) {
              font-style: normal;
              font-weight: bold;
              font-size: 16px;
              line-height: 20px;
              margin-bottom: 0px;
            }
          }
        }
        .btn {
          font-size: 14px;
          line-height: 17px;
          font-weight: 600;
          text-align: center;
          color: $bleu-edenred;
          padding: 8px 12px;
          border-width: 2px;
          border-color: $bleu-edenred;
          margin-bottom: 22px;

          @media (min-width: 768px) {
            font-size: 15px;
            line-height: 18px;
            margin-bottom: 0px;
          }
        }

        .availability {
          font-weight: normal;
          font-size: 14px;
          line-height: 17px;
          margin-bottom: 7px;
          display: flex;
          align-items: center;
          justify-content: center;

          @media (min-width: 768px) {
            justify-content: flex-start;
            font-size: 15px;
            line-height: 22px;
            margin-left: 37px;

            svg {
              width: 19px;
              height: 19px;
              margin-right: 17px;
            }
          }

          svg {
            width: 16px;
            height: 16px;
            margin-right: 10px;
          }
        }
      }
    }
  }
}
