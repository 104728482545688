@import 'src/_variable';

.modal {
  z-index: 2147483647;
}

.modal-equipment {
  max-width: 577px;

  &.modal-lg {
    width: 1157px;
    max-width: 90%;
  }
}

.modal_rdv {
  .modal-body {
    padding-left: 30px !important;
    padding-right: 30px !important;
    padding-bottom: 32px !important;

    h2 {
      margin-bottom: 24px !important;
    }
  }

  .modal-content {
    padding-bottom: 0px !important;
  }
}

.modal-backdrop {
  background-color: $bleu_edenred;
}

.modal-content {
  border: 0px;
  box-shadow: 10px 10px 10px rgba(22, 32, 86, 0.6);
  border-radius: 10px;
  padding-bottom: 16px;

  @media (min-width: 768px) {
    padding-bottom: 50px;
  }

  .modal-header {
    border-bottom: 20px;
    padding-bottom: 0px;

    .btn-close {
      background: none;
      padding: 16px;
      padding-bottom: 20px;
      outline: none;
      opacity: 1;
      width: auto;
      height: auto;
      color: $bleu-edenred;

      .closeSvg {
        width: 16px;
        height: 16px;
        @media (min-width: 768px) {
          width: 24px;
          height: 24px;
        }
      }
    }

    .return {
      padding-bottom: 20px;
      padding-top: 10px;
      font-size: 13px;
      line-height: 16px;
      outline: none;
      float: left;
      text-shadow: 0 1px 0 #fff;
      background-color: transparent;
      border: 0;
    }
  }

  .modal-body {
    padding-top: 6px;
    padding-left: 30px;
    padding-right: 30px;

    @media (min-width: 768px) {
      padding-left: 80px;
      padding-right: 80px;
    }

    h2 {
      font-weight: bold;
      font-size: 18px;
      line-height: 22px;
      text-align: left;
      color: $bleu-edenred;
      margin-bottom: 23px;

      @media (min-width: 768px) {
        font-size: 30px;
        line-height: 37px;
        margin-bottom: 48px;
      }
    }

    label {
      font-weight: normal;
      font-size: 12px;
      line-height: 15px;
      color: $bleu-edenred;
      margin-bottom: 9px;

      @media (min-width: 768px) {
        font-size: 12px;
        line-height: 16px;
        margin-bottom: 19px;
      }
    }

    textarea {
      background: #ffffff;
      border: 2px solid #dadada;
      border-radius: 10px;
      resize: vertical;
      padding: 23px 25px;
      font-size: 13px;

      @media (min-width: 768px) {
        font-size: 16px;
        line-height: 24px;
      }
    }

    button:not(.btn-ec)[type='submit'] {
      width: 100%;
      font-size: 14px;
      line-height: 17px;
      padding-top: 10px;
      padding-bottom: 10px;
      margin-top: 16px;

      @media (min-width: 768px) {
        width: 209px;
        font-size: 15px;
        line-height: 18px;
      }
    }
  }
}
