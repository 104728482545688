@import 'src/_variable';

.prochain_rdv {
  overflow: hidden;
  padding-bottom: 25px !important;

  // .prochain_rdv_row{
  //     max-height: 350px;

  //     @media (min-width: 1350px) {
  //         max-height: 285px;
  //     }
  // }

  .prochain_rdv_row {
    width: 100%;
    position: relative;
  }

  .rect_info_title_rdv_suivants {
    margin-bottom: 15px !important;
  }

  .img_people {
    position: absolute;
    right: -40px;
    top: 0px;
    width: 249px;
    overflow: hidden;
    padding: 0px;

    @media (min-width: 1140px) {
      right: 35px;
    }
  }

  .mini_card_rdv {
    .title-span {
      display: none !important;
    }
  }
}
