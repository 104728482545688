@import 'src/_variable';

.modal_congrat_opinion_rdv {
  .modal-body {
    padding: 16px !important;
    @media (min-width: 768px) {
      padding: 32px 80px !important;
    }

    svg {
      margin-bottom: 16px;
    }

    p {
      margin-bottom: 24px;

      @media (min-width: 768px) {
        margin-bottom: 32px;
      }
    }
  }
}
