.modal_timeline {
  button {
    padding: 6px 12px;
  }
  .table.billTimeline {
    tr {
      height: 44px;
    }
  }
}
