@import 'src/_variable';

.postpone-appointment {
  .header-content-postpone-appointment {
    margin-bottom: 18px;

    @media (min-width: 768px) {
      margin-bottom: 16px;
    }
    span,
    a {
      font-weight: bold;
      font-size: 13px;
      line-height: 16px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;

      &.annuler {
        svg {
          margin-right: 10px;
          width: 12px;
          height: 12px;
          path {
            stroke: $placeholder;
          }
        }
      }
    }
  }

  .rect_info {
    & > div {
      // min-height: 677px;
    }
  }

  .badge {
    font-size: 14px;
    height: auto;
    padding: 7px;
    white-space: break-spaces;
    line-height: 18px;
    max-width: 80%;
  }

  .title {
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 24px;
    width: 100%;
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;

    span {
      display: block;
      margin-top: 4px;
      font-weight: normal;
      font-size: 12px;
      line-height: 16px;
    }

    @media (min-width: 768px) {
      margin-bottom: 26px;
    }

    @media (min-width: 1200px) {
      flex-direction: row;
      justify-content: space-around;
      align-items: flex-end;
    }

    .rappel-rdv {
      font-weight: 600;
      font-size: 14px;
      line-height: 22px;

      .card {
        margin-bottom: 24px;
        margin-top: 8px;

        @media (min-width: 1200px) {
          margin-top: 8px;
          margin-bottom: 0px;
        }

        .card-text {
          margin-bottom: 0px;
        }

        span {
          margin-top: 0px;
        }
      }
    }
  }

  .content-postpone-appointment {
    width: 100%;
    padding: 0px 29px;
  }
}
