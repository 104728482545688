.loading-screen {
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 1001;
  background: #ffffff;
  mix-blend-mode: normal;
  opacity: 0.8;
}
