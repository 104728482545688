.notification-ec {
  border-top: none !important;
  border-right: none !important;
  border-bottom: none !important;
  border-left-width: 5px !important;
  border-left-style: solid !important;
  border-radius: 5px !important;
  min-height: 62px !important;
  box-sizing: border-box !important;

  .card-body {
    padding: 9.5px 16px;
    display: flex;
    align-items: center;
    flex: 1 1 auto;

    .text-content {
      font-weight: 500;
      font-size: 10px;
      line-height: 25px;
      letter-spacing: -0.3px;
    }

    .title {
      font-size: 14px;
      line-height: 25px;
      letter-spacing: -0.3px;
      margin-right: 40px;
    }

    .btn {
      font-weight: 600;
      font-size: 12px;
      line-height: 15px;
      margin-left: 16px;
      padding: 0.375rem 0.75rem;
    }

    .info-icon {
      width: 30px;
      height: 30px;
      margin-right: 16px;
    }
  }

  &.has-close-button {
    position: relative;
    .content-container {
      width: calc(100% - 24px);
    }

    .close {
      position: absolute;
      top: 10px;
      right: 10px;
      width: 20px;
      height: 20px;

      button {
        box-sizing: border-box;
        width: 20px !important;
        height: 20px !important;
      }
    }
  }
}
