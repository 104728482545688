@import 'src/_variable';

.create-appointment {
  .header-content-create-appointment {
    margin-bottom: 18px;

    @media (min-width: 768px) {
      margin-bottom: 16px;
    }
    span,
    a {
      font-weight: bold;
      font-size: 13px;
      line-height: 16px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;

      &.precedent {
        svg {
          margin-right: 13px;
          width: 18px;
          height: 12px;
        }
      }

      &.annuler {
        svg {
          margin-right: 10px;
          width: 12px;
          height: 12px;
          path {
            stroke: $placeholder;
          }
        }
      }
    }
  }

  .rect_info {
    & > div {
      // min-height: 677px;
    }
  }
}
