.maintenance-page {
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 56px;

  @media (min-width: 768px) {
    align-items: start;
  }

  .edenred-logo {
    margin-bottom: 48px;
    min-width: 179px;
    min-height: 48px;
    display: block;
  }

  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    h1 {
      font-weight: 700;
      font-size: 42px;
      line-height: 51px;
      text-align: center;
      order: 1;
    }

    span {
      font-weight: 400;
      font-size: 16px;
      line-height: 22px;
      text-align: center;
      order: 3;
    }

    .img-maintenance {
      max-width: 100%;
      height: auto;
      margin: 24px 0px;
      order: 2;
    }

    @media (min-width: 768px) {
      span {
        order: 2;
      }

      .img-maintenance {
        order: 3;
      }
    }
  }
}
