@import 'src/_variable';

.container-not-found {
  min-height: calc(100vh - 18px) !important;
  align-items: center;
  justify-content: center;

  .error-404 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1280px;

    h1 {
      margin-bottom: 30px;
      font-weight: 700;
      color: $bleu-edenred;
      font-size: 40px;
      line-height: 4.6rem;
    }

    p {
      margin-bottom: 20px;
      color: $bleu-edenred;
      font-size: 20px;
    }

    .btn {
      padding-left: 30px;
      padding-right: 30px;
      font-weight: 700;
      min-height: 50px;
      width: 100%;

      @media (min-width: 768px) {
        width: auto;
      }

      &:hover {
        color: $bleu-edenred !important;
      }
    }

    .image {
      display: none;
      @media (min-width: 1200px) {
        display: block;
      }
    }
  }
}
