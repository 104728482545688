@import 'src/_variable';

.card .congrat .card-body {
  h4.card-title {
    margin-bottom: 32px;

    @media (min-width: 540px) {
      font-weight: bold;
      font-size: 32px;
      line-height: 39px;
      margin-top: 0px;
      margin-bottom: 25px;
    }
  }

  img {
    margin-bottom: 24px;
    width: 279px;
    height: 128px;

    @media (min-width: 540px) {
      width: 100%;
      max-width: 100%;
      height: auto;
    }
  }

  p.success {
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    color: $bleu-edenred;
    margin-bottom: 16px;
    padding: 0px;
  }

  p:not(.success) {
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    color: $bleu-edenred;
    margin-bottom: 32px;
    padding: 0px;

    @media (min-width: 540px) {
      margin-bottom: 60px;
    }
  }

  button {
    font-weight: 600;
    font-size: 15px;
    line-height: 18px;
    text-align: center;
    padding-top: 15px;
    padding-bottom: 15px;

    @media (min-width: 540px) {
      margin-bottom: 30px;
    }
  }
}
