@import '../../../_variable.scss';

.btn-ec {
  font-size: 15px;
  line-height: 18px;
  margin: 0 auto 13px;
  padding: 8px 24px;

  &.btn-primary {
    &:hover {
      background: white;
      color: $bleu_edenred;
      border-color: #121a45;
    }
  }

  &.btn-block {
    width: 100%;
  }
}
