@import 'src/_variable';

.equipment {
  .rect-info-content {
    display: inline;
  }
  .questionIcon {
    width: 15px;
    height: 15px;
    cursor: pointer;
  }

  .skeleton {
    section {
      @media (min-width: 992px) {
        text-align: center;
      }
    }

    .button {
      @media (min-width: 992px) {
        height: 54px !important;
      }
    }
  }

  div.reactTooltipEquipment {
    background: #d5f0fe;
    box-shadow: 0px 10px 20px rgba(208, 214, 222, 0.5);
    border-radius: 3px;
    max-width: 250px;
    color: black;
    font-size: 13px;
    line-height: 20px;
    color: #252525;

    @media (min-width: 992px) {
      max-width: 315px;
      font-size: 10px;
      line-height: 12px;
    }

    &.show {
      opacity: 1;
    }

    &::after {
      border-left-color: #d5f0fe;
      border-top: 10px solid transparent;
      border-bottom: 10px solid transparent;
      right: -10px;
      margin-top: -10px;
      border-left-width: 10px;
    }
  }

  .rect_info {
    &.rect_info_licence {
      .rect_info_title {
        @media (min-width: 1200px) {
          margin-bottom: 41px;
          margin-top: 10px;
        }
      }
    }

    .rect_info_title {
      @media (min-width: 1200px) {
        text-align: center;
      }
    }

    &.rect_equipment_carousel {
      > div {
        padding-right: 0px;
        @media (min-width: 992px) {
          padding-right: 15px;

          .slick-slider {
            margin-right: 17px;
          }
        }

        .rect_info_title {
          margin-bottom: 21px;
          text-align: left;

          @media (min-width: 1200px) {
            margin-bottom: 25px;
          }
        }
      }
    }
    > div {
      button:not(.slick-arrow) {
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        padding: 8px 25px;
      }

      .licence_type {
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        color: $bleu-edenred;
        margin-bottom: 0px;
        display: flex;
        flex-direction: column;
        & > span:not(:empty) {
          margin-bottom: 5px;
        }

        svg {
          width: 38px;
        }

        @media (min-width: 1200px) {
          display: flex;
          justify-content: center;
          font-weight: bold;
          font-size: 20px;
          line-height: 24px;
          text-align: center;
        }
      }

      p {
        @media (min-width: 1200px) {
          text-align: center;
        }
      }
    }
  }
}
