@import 'src/_variable';

.create-account {
  padding-bottom: 10px;
  .card {
    .card-header {
      background: white;
      border-bottom: 0px;
      display: flex;
      justify-content: center;
      padding: 0px;
      padding-bottom: 32px;
      .steps {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;

        .step {
          width: 50px;
          height: 50px;
          border-radius: 50%;
          border: 3px solid transparent; //////////
          background: white;
          color: rgb(165, 165, 165);
          font-weight: bold;
          display: flex;
          justify-content: center;
          align-items: center;

          &.active {
            border-color: $bleu_ciel_edenred;
            color: black;
          }

          svg {
            width: 24px;
            height: 24px;
          }
        }

        .separator {
          width: calc(50% - 70px - 16px);
          height: 6px;
          border-radius: 3px;
          background: transparent; //////////
          &.active {
            background: $bleu_ciel_edenred;
          }
        }
      }
    }
  }
  .congrat {
    .icon-presentation-image {
      animation: animationThumb 2s 2 forwards;

      @keyframes animationThumb {
        0% {
          transform: rotate(0deg);
        }
        50% {
          transform: rotate(-10deg);
        }
        100% {
          transform: rotate(0deg);
        }
      }
    }
  }
}
