@import 'src/_variable';

.dropdown-button {
  .dropdown-toggle {
    border-left: 1px solid white;

    &::after {
      font-family: 'Font Awesome 5 Free';
      font-weight: 600;
      content: '\f078';
      border: none;
      vertical-align: auto;
    }
  }

  .dropdown-menu {
    inset: 35px 0 auto auto !important;
    transform: none !important;
    flex-direction: column;

    &.show {
      display: flex;
    }

    .dropdown-item {
      cursor: pointer;
      font-size: 16px;
      line-height: 22px;
      color: $text_courant;

      &:active {
        background-color: $bleu_edenred;
        color: $blanc;
      }
    }
  }
}
