.booking-carousel-container {
  .arrow--prev,
  .arrow--next {
    padding: 10px 21px;
    width: auto;
    height: auto;
  }

  .arrow--prev {
    left: 0px;
    margin-right: 16px !important;
    margin-left: 0px;
  }

  .arrow--next {
    right: 0px;
    margin-left: 16px !important;
    margin-right: 0px !important;
  }

  .carousel-hours {
    padding: 0 57px; // taille d'une flèche : 57px
    min-height: 100px;
  }

  .rendez-vous {
    .swiper {
      padding: 0 1px;
    }
    .swiper-slide {
      align-items: flex-start;
    }

    & .carousel-days {
      width: 100% !important;
      margin-top: 10px;
      margin-bottom: 8px;
    }
  }
}
