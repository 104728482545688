@import 'src/_variable';

.prochain_rdv {
  .slick-slide {
    margin-left: 25px;

    &:first-child {
      margin-left: 0px;
    }
  }
  /* the parent */
  .slick-list {
    // margin: 0 -20px 0 -12.5px;
    padding-bottom: 15px;
    margin-bottom: -15px;

    @media (min-width: 768px) {
      // margin: 0 -20px 0 -18.5px;
    }
  }
}

.card.card-rdv {
  border: 1px solid rgba(126, 142, 170, 0.13);
  box-sizing: border-box;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.09);
  border-radius: 10px;
  overflow: hidden;

  .card-header {
    background-color: $bleu_ciel_card;
    padding-bottom: 10px;
    border-bottom: 0px;

    .h5,
    h5 {
      font-weight: 600;
      font-size: 14px !important;
      line-height: 17px !important;
      margin: 0 !important;
    }
  }

  .btn-link {
    .btn {
      padding-top: 10px;
      padding-bottom: 10px;
      width: 219px;
    }
  }

  .card-icon {
    & {
      margin-right: 32px;
    }
    .icon {
      margin-right: 8px;

      & > svg {
        width: 18px;
        height: 18px;

        @media (min-width: 540px) {
          width: 32px;
          height: 32px;
        }

        path {
          stroke: $placeholder;
        }
      }
    }

    span {
      &.title-span {
        display: none;
      }
      font-style: normal;
      font-weight: normal;
      font-size: 10px;
      line-height: 12px;

      @media (min-width: 540px) {
        display: inline;

        &,
        &.title-span {
          display: block;
        }
      }
    }

    .h5,
    h5 {
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      margin: 0 !important;
      z-index: 2;

      @media (min-width: 540px) {
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;

        &.text-big {
          font-weight: 700 !important;
        }
      }
    }

    small {
      font-style: normal;
      font-weight: normal;
      font-size: 10px;
      line-height: 12px;
      z-index: 2;
    }

    .btn {
      font-weight: 600;
      font-size: 12px !important;
      line-height: 15px !important;
    }
  }

  .card-body {
    .text-annuler-rdv {
      font-weight: 600;
      font-size: 15px;
    }

    .card-text {
      margin-bottom: 8px;

      // @media (min-width: 540px) {
      //     margin-bottom: 24px;
      // }

      .card-icon {
        &:not(:last-child) {
          margin-bottom: 10px;

          @media (min-width: 540px) {
            margin-bottom: 16px;
          }
        }
      }
    }

    .btn {
      font-weight: 600;
      font-size: 12px !important;
      line-height: 15px !important;

      @media (min-width: 768px) {
        font-size: 15px !important;
        line-height: 18px !important;
      }
    }
  }

  &.mini_card_rdv {
    max-width: 241px;
    .card-icon {
      .icon {
        & > svg {
          width: 20px;
          height: 20px;
        }
      }
    }

    .card-header {
      padding: 10px 16px;
    }

    .card-body {
      position: relative;
      padding: 12px 16px;
      p {
        margin-bottom: 8px;
        z-index: 2;
      }

      .btn {
        font-weight: 600;
        font-size: 12px !important;
        line-height: 15px !important;
        z-index: 2;
      }

      .calendar_logo {
        position: absolute;
        bottom: -6px;
        right: -5px;
        width: 70px;
        height: 70px;
        z-index: 0;
        path {
          stroke: #e5e5e5;
        }
      }
    }
  }

  div.reactTooltip {
    margin-top: 0px !important;
  }
}
