@import 'src/_variable';

.booking {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 100%;

  &.disabled {
    .slider-container {
      pointer-events: none;
    }
  }

  h3 {
    background: #5f9ea0;
    color: #fff;
    font-size: 36px;
    line-height: 100px;
    margin: 10px;
    padding: 2%;
    position: relative;
    text-align: center;
  }
  .app {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .selector-month {
    display: flex;
    margin-bottom: 16px;
  }
  .month {
    background: #ffffff;
    border: 2px solid #162056;
    box-sizing: border-box;
    border-radius: 25px;
    padding: 8px 12px;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    text-align: center;
    color: #162056;
    text-transform: capitalize;
    cursor: pointer;
    &:nth-child(2) {
      margin: 0 24px;
    }
    &.disabled {
      opacity: 0.4;
      pointer-events: none;
    }
  }
  .month.selected {
    background: #162056;
    color: white;
  }
  .slider-container {
    width: 759px;
    max-width: 100%;
    border: 1px solid #ced4da;
    box-sizing: border-box;
    border-radius: 5px;
  }
  .slick-slider.nav2 {
    margin: 24px auto 12px;
    width: calc(100% - 140px);
    min-height: 20px;
    align-items: flex-start !important;
    @media (min-width: 768px) {
      min-height: 150px;
    }
  }
  .slick-prev,
  .slick-next {
    background: transparent !important;
    padding: 0px 20px;
    opacity: 0.8 !important;

    &.slick-disabled {
      opacity: 0.4 !important;
      cursor: initial !important;
    }

    &:focus {
      background: transparent;
      opacity: 1 !important;
    }
  }
  .slick-prev {
    transform: rotate(180deg) !important;
    margin-left: -10%;
  }
  .slick-next {
    margin-right: -10%;
  }
  .slick-list {
    width: 100%;
  }
  .hour {
    width: 105px;
    height: 37px;
    margin: 0 auto;
    box-sizing: border-box;
    border-radius: 5px;
    padding: 10px;
    margin-bottom: 12px;
    font-weight: 600;
    font-size: 14px;
    line-height: 120%;
    text-align: center;
    cursor: pointer;
    border: 1px solid #cccccc;
    color: #162056;
    transition: all 0.3s;

    &:not(.skeleton) {
      background: #ffffff;
    }
    &.selected {
      border: 1px solid #162056;
      background: #162056;
      color: #ffffff;
    }
    &:hover {
      border: 1px solid #162056;
    }
    &.disabled {
      opacity: 0.3;
      pointer-events: none;
    }
  }
  .slider-header {
    margin-top: 10px;
    margin-bottom: 8px;
    border-bottom: 1px solid #ced4da;
  }
  .nav1 {
    margin: auto;
    margin-top: 10px;
    margin-bottom: 8px;
    width: 82%;
  }
  .nav2 {
    margin-top: 24px;
    margin-bottom: 2px;
  }
  .date {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-weight: bold;
    font-size: 14px;
    line-height: 120%;
    text-align: center;
    color: #162056;
    text-transform: capitalize;
    span {
      font-weight: normal;
      font-size: 14px;
      line-height: 120%;
      text-align: center;
      color: #162056;
      text-transform: lowercase;
    }
  }
  .date.disabled {
    opacity: 0.3;
    // display: none;

    @media (min-width: 1200px) {
      display: flex;
    }
  }

  .div-skeleton {
    display: flex !important;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .btn {
    margin-top: 30px;
    margin-bottom: 0px;
    font-size: 16px;
    line-height: 20px;

    padding: 13px 23px;

    @media (min-width: 768px) {
      padding: 13px 28px;
    }
  }

  .deadline-warning {
    border: 1px solid #fb483a;
    color: #fb483a;
    display: flex;
    margin-top: 24px;
    margin-bottom: 24px;
    width: 759px;
    max-width: 100%;

    .side-rect {
      background: #fb483a;
      width: 46px;
      min-width: 46px;
      display: flex;
      justify-content: center;
      align-items: center;

      svg {
        width: 24px;
        height: 24px;
      }
    }

    .content {
      padding: 12px 14px;

      .body-text {
        font-weight: bold;
        font-size: 14px;
        line-height: 150%;
        margin-bottom: 12px;
      }

      .tel-number {
        font-weight: normal;
        font-size: 16px;
        line-height: 22px;

        span {
          font-weight: 500;
          font-size: 18px;
          line-height: 22px;
        }
      }
    }
  }

  .form-check {
    width: 700px !important;
    max-width: calc(100% + 3px);

    @media (min-width: 992px) {
      max-width: calc(100% - 55px);
    }

    @media (min-width: 768px) {
      width: 763px !important;
      max-width: 100%;
    }
    label {
      font-weight: 600;
      font-size: 14px;
      line-height: 16px !important;
      margin-bottom: 32px !important;
      letter-spacing: -0.3px;
    }
  }
}

.rendez-vous .badge {
  background: $bleu_ciel_card;
  padding: 4px 8px;
  margin-bottom: 12px;
  color: $bleu-edenred;
  height: auto;
  width: auto;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  svg {
    width: 24px;
    height: 24px;
    margin-right: 8px;
  }

  @media (min-width: 768px) {
    font-size: 14px;
    line-height: 17px;
  }
}
