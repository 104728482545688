.webinar {
  .description-card-webinar {
    font-size: 14px;
  }

  a {
    width: 100%;
    max-width: 229px;
  }
  .btn-webinar {
    padding: 12px 12px;
    font-size: 15px;
    min-height: 52px;
  }
}
