@import 'src/_variable';

#sidebar-wrapper {
  position: fixed;
  bottom: 0px;
  left: 0px;
  z-index: 100;
  width: 100%;
  height: 64px;
  background: $blanc;
  box-shadow: 0px -4px 10px rgba(0, 0, 0, 0.1);
  transition: height 0.3s;

  &.toggled {
    @media (max-width: 992px) {
      height: 187px;

      .button-menu-mobile {
        span {
          margin-top: 6px;
        }
      }

      .list-group {
        padding-top: 40px;
        justify-content: flex-start;
        .button-menu-mobile {
          display: none !important;
        }
      }

      .nav-link {
        width: 25% !important;

        &:nth-child(3) {
          margin-left: 0% !important;
        }
      }
    }
  }

  @media (min-width: 992px) {
    position: static;
    height: 766px;
    min-width: 375px;
    width: 375px;
    box-shadow: 0px 10px 20px rgba(208, 214, 222, 0.5);
    border-radius: 10px;
  }

  .sidebar-heading {
    display: none;

    @media (min-width: 992px) {
      display: block;
      font-weight: bold;
      font-size: 30px;
      line-height: 37px;
      text-align: center;
      color: $bleu-edenred;
      margin-top: 35px;
      margin-bottom: 28px;
    }
  }

  .button-menu-mobile {
    width: 54px;
    height: 54px;
    background: $bleu-edenred;
    border-radius: 10px;
    color: white;
    font-weight: 500;
    font-size: 10px;
    line-height: 12px;
    text-align: center;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    filter: drop-shadow(0px 9px 40px rgba(22, 32, 86, 0.35));
    margin-top: -15px;
    cursor: pointer;
    order: 2;
    z-index: 3;

    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }

  .list-group {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    // justify-content: space-between;
    padding-top: 0px;
    height: 64px;
    transition: padding 0.3s;

    // transition-delay: .01s;

    @media (min-width: 992px) {
      flex-direction: column;
      flex-wrap: initial;
      height: auto;
    }

    .button-menu-mobile {
      background: transparent;
      position: relative;
      left: 0%;
      transform: translateX(0%);
      z-index: 1;
      width: 20%;

      span {
        margin-top: 3px;
      }
    }

    .nav-link {
      background: $blanc;
      padding: 12px 0px;
      margin-left: 0%;
      width: 20%;
      transition: width 0.3s, margin 0.3s;

      &:nth-child(3) {
        margin-left: 20%;
      }

      @media (min-width: 992px) {
        position: relative;
        overflow: hidden;
        height: 64px;
        width: auto;
        border-top: 1px solid #eaeaea;

        &:nth-child(3) {
          margin-left: 0%;
        }

        &:last-child {
          border-bottom: 1px solid #eaeaea;
        }

        &::after {
          position: absolute;
          content: '';
          width: 9px;
          height: 100%;
          right: -9px;
          top: 0px;
          background: #7e8eaa;
          transition: right 0.3s;
        }

        &:hover {
          &::after {
            right: 0px;
            background: #7e8eaa;
          }
        }

        &.active {
          &::after {
            right: 0px;
            background: #162056;
          }
        }
      }

      &.active {
        p {
          color: $bleu-edenred;
          position: relative;
        }

        svg {
          path {
            stroke: $bleu-edenred;
          }
        }

        & > div {
          position: relative;
          &::after {
            content: '';
            position: absolute;
            bottom: -6px;
            left: 50%;
            transform: translateX(-50%);
            width: 10px;
            height: 2px;
            background: $bleu-edenred;
            border-radius: 50px;

            @media (min-width: 992px) {
              content: none;
            }
          }
        }
      }

      &.disabled {
        opacity: 0.4;
      }

      & > div {
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        height: 100%;
        width: 100%;

        @media (min-width: 992px) {
          flex-direction: row;
          justify-content: flex-start;
          padding-left: 38px;
        }

        .icon {
          &.has-new-notification {
            position: relative;

            &::before {
              content: '';
              position: absolute;
              top: 50%;
              left: -7px;
              height: 8px;
              width: 8px;
              transform: translate(-50%, -50%);
              border-radius: 50%;
              background: $new_badge;

              @media (min-width: 992px) {
                display: none;
              }
            }
          }
        }

        svg {
          width: 18px;
          height: 18px;

          @media (min-width: 992px) {
            width: 20px;
            height: 20px;
          }

          path {
            stroke: $placeholder;
          }
        }

        p {
          font-weight: 500;
          font-size: 10px;
          line-height: 12px;
          text-align: center;
          min-height: 0px;
          margin-top: 6px;
          color: $placeholder;
          position: relative;

          @media (min-width: 992px) {
            font-weight: bold;
            font-size: 16px;
            line-height: 20px;
            margin-left: 23px;
            margin-top: 0px;

            &.has-new-notification {
              &::before {
                content: '';
                position: absolute;
                top: 50%;
                right: -20px;
                height: 14px;
                width: 14px;
                transform: translate(50%, -50%);
                border-radius: 50%;
                background: $new_badge;
              }
            }
          }
        }
      }
    }
  }

  // min-height: 100%;
  // margin-left: calc(-300px - 1rem);
  // -webkit-transition: margin .25s ease-out;
  // -moz-transition: margin .25s ease-out;
  // -o-transition: margin .25s ease-out;
  // transition: margin .25s ease-out;
  // position: absolute;
  // z-index: 50;

  // p{
  //   max-width: 85%;
  //   color: #7E8EAA;
  //   font-size: 16px;
  //   line-height: 20px;
  // }

  // .list-group{
  //   overflow: hidden;
  // }

  // #menu-toggle{
  //   position: absolute;
  //   top:10px;
  //   right: -38px;
  //   z-index: 51;
  //   transition:right .3s;

  // }

  // &.toggled{
  //   margin-left: 0;
  //   #menu-toggle{
  //     right: 0px;
  //   }
  // }

  // @media (min-width: 992px) {
  //   position: relative;
  //   margin-left: 0rem;

  //   &.toggled{
  //     margin-left: 0rem;
  //   }
  // }
}

// #sidebar-wrapper .sidebar-heading {
//   padding: 0.875rem 1.25rem;
//   font-size: 1.2rem;
//   font-weight: bold;
//   line-height: 37px;
//   text-align: center;
//   color: #162056;
// }

// #sidebar-wrapper .list-group {
//   width: 300px;
// }

// #page-content-wrapper {
//   min-width: 100vw;
// }

// #sidebar-wrapper .nav-link{
//   height: 65px;
//   display: flex;
//   align-items: center;
//   border-top: 1px solid  #EAEAEA;
//   position: relative;

//   &.disabled{
//     opacity: 0.4;
//   }

//   svg{
//     width: 16px;

//     path{
//       stroke:#7E8EAA;
//     }
//   }

//   &::after{
//     position: absolute;
//     content:'';
//     width: 9px;
//     height: 100%;
//     right: -9px;
//     top:0px;
//     background: #7E8EAA;
//     transition: right .3s;

//   }

//   &:hover{
//     &::after{
//       right: 0px;
//       background:#7E8EAA;
//     }
//   }

//   &:last-child{
//     border-bottom: 1px solid  #EAEAEA;
//   }

//   &.active{
//     font-weight: bold;
//     position: relative;

//     svg path{
//       stroke : #162056;
//     }

//     p{
//       color:#162056;
//     }

//     &::after{
//       right: 0px;
//       background: #162056;
//     }
//   }

// }

// @media (min-width: 992px) {
//   // #sidebar-wrapper {
//   //   margin-left: 0;
//   // }

//   #page-content-wrapper {
//     min-width: 0;
//     width: 100%;
//   }

//   #wrapper.toggled #sidebar-wrapper {
//     margin-left: -15rem;
//   }
// }
