@import 'src/_variable';

.card .card-body .step1 {
  color: $bleu-edenred;

  .loaderComponent {
    height: 280px;
  }

  .card-title {
    font-weight: bold;
    font-size: 24px;
    line-height: 29px;
    text-align: center;
    color: $bleu-edenred;
    margin-bottom: 32px;

    @media (min-width: 540px) {
      margin-top: 0px;
      text-align: left;
      font-size: 32px;
      line-height: 39px;
      margin-bottom: 30px;
    }
  }

  .card-text {
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    color: $bleu-edenred;
    text-align: left;
    margin-bottom: 24px;
    padding: 0px;

    @media (min-width: 540px) {
      font-size: 16px;
      line-height: 22px;
    }
  }

  form {
    width: 100%;
    .form-group {
      &.input-email {
        margin: 0 auto;
        @media (min-width: 540px) {
          max-width: 350px;
        }

        label {
          font-weight: normal;
          font-size: 12px;
          line-height: 16px;
          margin: 0 auto;
        }
        .input {
          display: flex;
          border-bottom: 2px solid $bleu-edenred;
          align-items: center;
          margin: 0 auto;
          margin-bottom: 24px;

          &.error {
            border-color: $erreur;
          }

          svg {
            width: 24px;
            height: 24px;
            margin-bottom: 0px;
            margin-left: 6px;
          }

          input {
            padding-left: 6px;
            padding-bottom: 10px;
            font-weight: 500;
            font-size: 14px;
            line-height: 24px;
            color: $bleu-edenred;
            border: 0px;
            width: 100%;

            &:focus {
              outline: none;
              box-shadow: none;
            }
          }

          & + ul {
            max-width: 350px;
            width: 350px;
            padding: 0px;
            margin: 0 auto;
            li {
              font-weight: normal;
              font-size: 12px;
              line-height: 17px;
              color: $erreur;
              list-style: none;
              margin-bottom: 10px;
            }
          }
        }
      }
    }

    .fake_captcha {
      display: flex;
      flex-direction: column;

      img {
        height: 70px;
        width: 279px;
        background: #fafafa;
        border: 1px solid gray;
        border-radius: 5px;
        margin: 0 auto;
        margin-bottom: 32px;
        line-height: 70px;
        text-align: center;

        @media (min-width: 540px) {
          height: 100px;
          width: 401px;
          line-height: 100px;
          margin-bottom: 5px;
        }
      }

      input#valid_email_form_captcha {
        margin: 0 auto;
        display: flex;
        border: none;
        border-bottom: 2px solid $bleu-edenred;
        align-items: center;
        margin: 0 auto;
        padding-left: 6px;
        padding-bottom: 10px;
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        margin-bottom: 8px;
        width: 100%;

        &:focus {
          outline: none;
          box-shadow: none;
        }

        @media (min-width: 540px) {
          max-width: 350px;
          width: 350px;
        }

        & + ul {
          max-width: 350px;
          width: 350px;
          padding: 0px;
          margin: 0 auto;
          li {
            font-weight: normal;
            font-size: 12px;
            line-height: 17px;
            color: $erreur;
            list-style: none;
          }
        }
      }
    }
  }

  button#submitMail {
    font-weight: 600;
    font-size: 15px;
    line-height: 18px;
    padding: 15px 0px;
    margin: 0 auto;
    margin-bottom: 12px;
    margin-top: 24px;
    display: block;

    @media (min-width: 540px) {
      max-width: 350px;
      margin-bottom: 18px;
    }

    @media (min-width: 768px) {
      max-width: 234px;
    }

    &.loading {
      padding: 9px 0px;
    }

    svg {
      margin-bottom: 0px;
      height: 30px;
      max-width: 100%;
    }
  }
}
