@import 'src/_variable';

.communication {
  padding: 22px 0px 0px 0px;

  @media (min-width: 768px) {
    padding-bottom: 29px;
  }
  .communication-content {
    max-width: 465px;
    width: 80%;
    margin: 0 auto;

    h5 {
      font-weight: bold;
      text-align: center;
      color: $bleu-edenred;
      font-size: 18px;
      line-height: 22px;

      @media (min-width: 768px) {
        font-size: 20px;
        line-height: 24px;
      }
    }

    form {
      .form-group {
        .form-check {
          display: flex;
          cursor: pointer;
          padding-left: 0px;

          label {
            margin-left: 10px;
            font-weight: normal;
            color: $bleu-edenred;
            font-size: 14px;
            line-height: 22px;
            cursor: pointer;

            @media (min-width: 768px) {
              font-size: 15px;
              line-height: 16px;
              margin-left: 20px;
            }
          }

          &:not(:last-child) {
            margin-bottom: 29px;
          }

          &:last-child {
            margin-bottom: 44px;

            @media (min-width: 768px) {
              margin-bottom: 64px;
            }
          }
        }
      }

      button {
        max-width: 404px;
        width: 100%;
        margin: 0px auto;
        font-style: normal;
        font-weight: 600;
        font-size: 15px;
        line-height: 18px;
        padding: 15px 0px;
      }
    }
  }
}
