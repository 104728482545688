$rouge_edenred: #f72717;
$bleu_edenred: #162056;
$bleu_fonce_edenred: #151d49;
$bleu_dodger: #006cfa;
$bleu_azur: #c7dfff;
$bleu_ciel_card: #d5f0fe;
$bleu_ciel_edenred: #96d9fb;
$text_courant: #252525;
$placeholder: #7e8eaa;
$erreur: #e80000;
$start: #25c8eb;
$encours: #ff603a;
$pending: #ff9e2a;
$validation: #39b54a;
$info: #96d9fb;
$item_menu: #7e8eaa;
$etape_en_cours: $item_menu;
$inactif: #afb5bc;
$blanc: #ffffff;
$gris_tres_clair: #f7f7f7;
$gris_clair: #e6eaef;
$gris: #afb5bc;
$gris_fonce: #676767;
$etape_inactive: #d4dae3;
$bleu_ciel_corpo: #25c8eb;
$new_badge: #f54f20;
$white: #fff;

$speed_animation: 0.18s;
$slow_animation: 0.24s;

$theme-colors: (
  'primary': $bleu_edenred,
  'red-edenred': $rouge_edenred,
  'bleu-ciel-edenred': $bleu_ciel_edenred,
  'bleu-ciel-card': $bleu_ciel_card,
  'bleu-ciel-corpo': $start,
  'bleu_dodger': $bleu_dodger,
  'bleu-azur': $bleu_azur,
  'gris-clair': $gris_clair,
  'gris': $gris,
  'gris-fonce': $gris_fonce,
  'success': $validation,
  'error': $erreur,
  'new': $new_badge,
  'placeholder': $placeholder,
  'validation': $validation,
  'start-color': $start,
  'info': $info,
  'pending': $pending,
);

$custom-control-indicator-size: 1.5rem;

$grid-breakpoints: (
  xxxs: 0,
  xxs: 320px,
  xs: 568px,
  sm: 667px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1440px,
  xxxl: 1600px,
);
$container-max-widths: (
  xxxs: 0,
  xxs: 320px,
  xs: 568px,
  sm: 667px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1440px,
  xxxl: 1600px,
);

$body-color: $text_courant;
$input-placeholder-color: $placeholder;
