@import 'src/_variable';

.profil-container {
  .tab-pane {
    padding: 25px 0px;

    @media (min-width: 540px) {
      padding: 31px 28px;
    }
  }

  .skeleton {
    .skeleton-input {
      @media (min-width: 992px) {
        width: 50% !important;
      }
    }
  }
}
