@import 'src/_variable';

.modal-cgu {
  color: $bleu-edenred;

  .modal-content .modal-body {
    padding-top: 24px;
    padding-bottom: 0px;

    @media (min-width: 768px) {
      padding-top: 73px;
    }
  }

  h2 {
    font-size: 20px;
    line-height: 150%;
  }

  p.content {
    font-weight: normal;
    font-size: 16px;
    line-height: 150%;
  }

  .conditions-generales {
    p {
      font-weight: normal;
      font-size: 12px;
      line-height: 16px;
      margin-bottom: 24px;

      span:first-child {
        display: block;
        margin-bottom: 15px;
      }
    }

    label {
      font-weight: 600 !important;
      font-size: 14px !important;
      line-height: 16px !important;
    }

    .file-download-icon {
      margin-left: 3px;
      height: 12px;
      width: 12px;
    }
  }

  button {
    font-weight: 600;
    font-size: 15px;
    line-height: 18px;
    padding: 10px 0px !important;
    margin: 0 auto;
    margin-top: 19px;
    width: 292px !important;
    max-width: 100% !important;
    margin-bottom: 32px !important;

    @media (min-width: 768px) {
      max-width: 292px;
      margin-top: 46px;
      margin-bottom: 0px !important;
      padding: 15px 0px !important;
    }
  }
}
