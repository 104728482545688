.container-connection-problem {
  .card-center {
    min-height: 0px;

    .card-body {
      .card-title {
        margin-bottom: 32px;
        @media (min-width: 768px) {
          font-weight: bold;
          font-size: 32px;
          line-height: 39px;
        }
      }

      img {
        margin-bottom: 32px;

        @media (min-width: 768px) {
          width: 424px;
        }
      }

      p {
        padding-left: 0px;
        padding-right: 0px;
      }

      .card-text {
        margin-bottom: 25px;
        text-align: left;

        @media (min-width: 768px) {
          padding-left: 10px;
          padding-right: 10px;
          text-align: center;
        }
      }

      .btn {
        padding: 17px 10px;
        font-size: 15px;
        line-height: 18px;
        font-weight: 600;

        @media (min-width: 768px) {
          max-width: 258px;
          margin: 0 auto;
          font-weight: 600;
          font-size: 15px;
          line-height: 18px;
        }

        &.modif-mail {
          margin-left: 0px;
          border-width: 2px !important;
          font-weight: 600;
          font-size: 15px;
          line-height: 18px;
          margin-bottom: 16px;
          padding-top: 15px;
          padding-bottom: 15px;

          @media (min-width: 768px) {
            margin-left: 10px;
          }
        }
      }
    }
  }
}
