@import 'src/_variable';

.step.motif {
  padding: 0px 0px;
  @media (min-width: 768px) {
    padding: 0px 29px;
  }

  .title-interlocuteur {
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 34px;
  }

  .interloculteur-infos {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    .icon-profile {
      width: 75px;
      height: 75px;
      margin-bottom: 24px;
    }

    .infos {
      font-size: 14px;
      line-height: 17px;
      margin-bottom: 32px;

      @media (min-width: 768px) {
        font-size: 18px;
        line-height: 22px;
      }

      .name {
        font-weight: bold;
        font-size: 24px;
        line-height: 29px;
        font-size: 18px;
        line-height: 22px;
        margin-bottom: 8px;

        @media (min-width: 768px) {
          font-size: 24px;
          line-height: 29px;
        }
      }
    }
  }

  .title {
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 32px;

    @media (min-width: 768px) {
      margin-bottom: 34px;
    }
  }

  .form-motif {
    .form-check,
    .custom-radio {
      margin-bottom: 12px;
      display: flex;
      align-items: center;

      .form-check-label,
      .custom-control-label {
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        min-height: 24px;
        span {
          font-weight: normal;
        }
      }
    }

    .btn {
      margin-top: 48px;
      margin-bottom: 27px;
      font-size: 16px;
      line-height: 20px;

      padding: 13px 23px;

      @media (min-width: 768px) {
        padding: 13px 28px;
      }
    }
  }
}
