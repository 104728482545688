@import 'src/_variable';

table.dataTable.thead-disabled {
  thead {
    display: none !important;
  }
}

table.dataTable thead {
  .sorting,
  .sorting_asc,
  .sorting_desc {
    background-image: none !important;
    padding-right: 5px;
    padding-left: 5px;

    &::after {
      font-size: 12px;
    }
  }

  .sorting {
    &::after {
      opacity: 0.2;
    }
  }

  .sorting_asc,
  .sorting_desc {
    &::after {
      opacity: 1;
    }
  }
}

table.dataTable thead .sorting::after {
  font-family: 'Font Awesome 5 Free';
  font-weight: 600;
  content: '\f078';
  margin-left: 7px;
}

table.dataTable thead .sorting_asc::after {
  content: '\f077' !important;
}
table.dataTable thead .sorting::after,
table.dataTable thead .sorting_desc::after {
  content: '\f078';
}

.dataTable {
  text-align: right;
  color: #4a4a4a;
  border-bottom: 1px solid #e6eaef !important;

  tbody {
    tr {
      min-height: 55px;

      &.selected {
        background: white !important;
        .select-checkbox {
          &::before {
            display: none;
          }
          &::after {
            display: block !important;
            font-size: 14px !important;
          }
        }
      }

      .select-checkbox {
        cursor: pointer;
        outline: none;
        &::before {
          margin-top: 0px !important;
          margin-left: 0px !important;
          top: 50% !important;
          transform: translateY(-50%) !important;
          font: normal normal normal 14px/1 'Font Awesome 5 Free';
          content: '\f0c8' !important;
          border: none !important;
          text-shadow: none !important;
        }

        &::after {
          display: none !important;
          font: normal normal normal 14px/1 'Font Awesome 5 Free';
          content: '\f14a' !important;
          margin-top: 0px !important;
          margin-left: 0px !important;
          top: 50% !important;
          transform: translateY(-50%) !important;
          text-shadow: none !important;
        }
      }

      &.parent {
        .control {
          &::before {
            content: '\f078' !important;
          }
        }

        & + tr.child {
          td.child {
            .dtr-details {
              max-width: 191px;
              @media (min-width: 576px) {
                max-width: 100%;
              }
              li {
                display: flex;
                justify-content: space-between;
                color: $bleu-edenred;
                font-style: normal;
                flex-wrap: wrap;
                font-size: 10px;
                line-height: 12px;

                span.dtr-title {
                  text-align: left;
                }
                .bill-actions {
                  width: 100%;
                  min-width: 100%;
                  font-weight: 600;
                  font-size: 10px;
                  line-height: 12px;
                  text-align: center;
                  flex-wrap: nowrap;
                  margin-top: 5px;
                  @media (min-width: 768px) {
                    margin-top: 0px;
                  }

                  button {
                    width: 100%;
                    max-width: 95px;
                    border: 1px solid $bleu-edenred;
                  }
                }
              }
            }
          }
        }
      }

      .control {
        &::before {
          background-color: transparent !important;
          font-family: 'Font Awesome 5 Free' !important;
          content: '\f054' !important;
          color: black !important;
          font-weight: 900;
          font-size: 18px;
          border: none !important;
          box-shadow: none !important;
          transform: translate(-50%, -50%);
          margin: 0px !important;
          color: $bleu-edenred !important;
        }
      }
    }

    td {
      font-size: 11px;
      border-color: #e6eaef;

      &.font-weight-bold {
        color: #162056;
        font-size: 12px;
      }
    }
  }

  .bill-actions {
    flex-wrap: wrap;

    button {
      // width: 100%;
      // max-width: 95px;
      margin-bottom: 2px;
      &:not(.btn-white-border) {
        border: 2px solid transparent;
      }
      &:hover {
        border: 2px solid $bleu-edenred;
      }
    }
  }

  thead {
    th {
      border: none !important;
      font-size: 15px;
      padding-bottom: 27px !important;
      color: #162056;
      font-weight: 600;
    }
  }

  td {
    vertical-align: middle;
  }

  button {
    font-size: 13px;
    border-radius: 25px;
    font-size: 12px;
    line-height: 15px;
    text-align: center;
    color: #ffffff;
    border: none;
    font-weight: 600;
    background: #162056;
  }
}

.dataTables_paginate.paging_simple_numbers {
  padding-top: 24px;
  display: flex;
  justify-content: center;

  .ellipsis {
    color: #162056;
    font-weight: 600;
  }
  .paginate_button.previous,
  .paginate_button.next {
    background: white;
    &:not(.disabled):hover {
      background: transparent;
      background-color: transparent;
      border: 1px solid transparent;
      color: #162056 !important;
    }
  }

  .paginate_button {
    width: 32px;
    height: 32px;
    line-height: 30px;
    text-align: center;
    background: #f7f7f7;
    padding: 0px;
    border-radius: 50%;
    font-size: 14px;
    font-weight: 500;
    color: #252525 !important;
    margin-left: 5px;
    transition: all 0.3s;

    &.previous {
      @media (min-width: 992px) {
        margin-right: 10px;
      }
    }

    &.next {
      @media (min-width: 992px) {
        margin-left: 10px;
      }
    }

    &:not(.disabled):hover {
      background: white !important;
      border: 1px solid #162056 !important;
      color: #162056 !important;
    }

    &.current {
      background: #162056;
      color: white !important;
      pointer-events: none;
      border: 0px;
    }
  }
}

.dataTables_wrapper {
  .dataTables_info {
    font-size: 14px;
    line-height: 150%;
    color: $bleu-edenred;
  }
}
