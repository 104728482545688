@import 'src/_variable';

.dashboard {
  // .react-loading-skeleton {
  //   height: 233px !important;
  //   @media (min-width: 768px) {
  //     height: 430px !important;
  //   }
  //   @media (min-width: 992px) {
  //     height: 430px !important;
  //   }
  // }

  .skeleton-interlocuteurs {
    flex-direction: row;
    @media (min-width: 1440px) {
      span {
        height: 100px !important;
        margin-bottom: 10px;
      }
      flex-direction: column;
    }
  }

  .etat,
  .question,
  .interlocuteur,
  .rdv {
    @media (min-width: 1440px) {
      padding-right: 0px;
    }
  }

  .question {
    & > div {
      padding-right: 15px !important;
    }
  }

  .rdv {
    .content {
      height: 100%;
    }
    .card.card-rdv.mini_card_rdv {
      max-width: 100%;

      .card-text {
        flex-direction: column;
      }
    }

    .btn {
      @media (max-width: 540px) {
        max-width: 155px !important;
      }
    }

    .no-rdv {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      height: 100%;

      svg {
        width: 179px;
        height: 90px;
        margin-bottom: 18px;
      }

      span {
        font-weight: normal;
        font-size: 14px;
        line-height: 150%;
        text-align: center;
        color: $bleu-edenred;
      }
    }
  }

  .rect_info {
    @media (min-width: 768px) {
      padding-right: 0px;
    }
  }

  .rect_info > div {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 16px 20px;

    @media (min-width: 768px) {
      padding: 38px !important;
      padding-bottom: 22px !important;
    }

    .rect_info_title {
      text-align: center;
      font-weight: bold;
      font-size: 16px;
      line-height: 20px;
      margin-bottom: 16px;

      @media (min-width: 768px) {
        font-size: 20px;
        line-height: 24px;
        margin-bottom: 30px;
      }
    }

    .content {
      font-weight: 500;
      font-size: 10px;
      line-height: 12px;
      margin-bottom: 18px;

      @media (min-width: 768px) {
        margin-bottom: 29px;
      }

      a {
        display: flex;
        font-weight: 500;
        font-size: 10px;
        line-height: 12px;
        align-items: center;
        margin-bottom: 13px;

        &:hover {
          text-decoration: none;
          color: $bleu-edenred;
        }

        @media (min-width: 768px) {
          font-size: 13px;
          line-height: 16px;
          margin-bottom: 24px;
        }

        &:last-child {
          margin-bottom: 0px;
        }

        svg {
          width: 21px;
          min-width: 21px;
          height: 21px;
          min-height: 21px;
          margin-right: 9px;

          @media (min-width: 768px) {
            width: 35px;
            min-width: 35px;
            height: 35px;
            min-height: 35px;
            margin-right: 21px;
          }

          path {
            stroke-width: 2px;
          }
        }
      }

      .status {
        font-weight: bold;
        font-size: 14px;
        line-height: 17px;
        margin-bottom: 10px;
        display: flex;
        justify-content: center;
        align-items: center;

        &.start {
          color: $start;
          svg {
            path {
              stroke: $start;
            }
          }
        }

        &.encours {
          color: $encours;
          svg {
            path {
              stroke: $encours;
            }
          }
        }

        &.lance {
          color: $validation;
          svg {
            path {
              stroke: $validation;
            }
          }
        }

        @media (min-width: 768px) {
          font-size: 20px;
          line-height: 24px;
          margin-bottom: 35px;
        }

        svg {
          margin-right: 13px;
          width: 25px;
          height: 25px;

          @media (min-width: 768px) {
            width: 40px;
            height: 40px;
            margin-right: 24px;
          }

          path {
            stroke: #f9531c;
          }
        }
      }

      .steps {
        display: flex;
        margin-bottom: 19px;

        @media (min-width: 768px) {
          margin-bottom: 12px;
        }

        .step {
          background: $etape_inactive;
          border: 1px solid transparent;
          height: 8px;
          width: 100%;

          @media (min-width: 768px) {
            height: 13px;
          }

          &.isActive {
            background: $bleu-edenred;
            border-color: $bleu-edenred;
          }

          &.isCurrent {
            background: $item_menu;
            border-color: $bleu-edenred;
          }

          &:not(:last-child) {
            margin-right: 4px;
          }
        }
      }

      .now {
        p.title {
          font-weight: normal;
          font-size: 12px;
          line-height: 22px;
          justify-content: center;
          text-align: center;
          margin-bottom: 0px;

          @media (min-width: 768px) {
            font-size: 16px;
            line-height: 22px;
          }
        }

        p:not(.title) {
          font-weight: bold;
          font-size: 10px;
          line-height: 22px;
          justify-content: center;
          color: #162056;
          text-align: center;

          @media (min-width: 768px) {
            font-size: 20px;
            line-height: 24px;
          }
        }
      }
    }

    .btn:not(.btn-ec) {
      font-weight: 600;
      font-size: 10px;
      line-height: 12px;
      max-width: 117px;
      margin: 0 auto;

      @media (min-width: 768px) {
        font-size: 15px;
        line-height: 18px;
        max-width: 229px;
        margin: 0 auto;
        padding-top: 15px;
        padding-bottom: 15px;
        margin-bottom: 13px;
      }
    }

    &.interlocuteurs {
      padding-left: 24px !important;
      padding-right: 24px !important;
      padding-bottom: 15px !important;

      .rect_info_title {
        margin-bottom: 16px;
      }

      .slick-slider {
        .slick-slide {
          &:not(:last-child) {
            margin-right: 16px;
          }
        }
      }

      .buttons {
        z-index: 3;
      }
    }

    .contacts {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 12px;

      .slick-list {
        width: 100%;
      }

      .contact {
        width: 250px;
        min-width: 250px;
        border: 1px solid rgba(126, 142, 170, 0.13);
        box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.09);
        border-radius: 10px;
        display: flex !important;
        flex-direction: column;
        align-items: flex-start;
        position: relative;
        overflow: hidden;
        padding: 16px;
        outline: none;

        @media (min-width: 768px) {
          width: 100% !important;
        }

        .info {
          background: $info;
          border-radius: 4px;
          font-weight: bold;
          font-size: 11px;
          line-height: 24px;
          text-align: center;
          color: #ffffff;
          padding: 0px 10px;
          margin-bottom: 8px;
        }

        .name {
          font-weight: bold;
          font-size: 16px;
          line-height: 24px;
          color: $bleu-edenred;
          margin-bottom: 4px;
        }

        .job {
          font-weight: normal;
          font-size: 12px;
          line-height: 16px;
          color: $bleu-edenred;
          margin-bottom: 9px;
        }

        .btn {
          margin-left: 0px;
          font-weight: 600;
          font-size: 12px;
          line-height: 15px;
          text-align: center;
          padding: 8px 12px;
          margin-bottom: 0px;
        }

        svg {
          position: absolute;
          bottom: -11px;
          right: -10px;
          height: 79px;
          width: 70px;

          path {
            stroke-width: 2px;
            stroke: $gris;
          }
        }
      }
    }
  }
}
