@import 'src/_variable';

.navbar-create-account {
  box-shadow: none;
  border-width: 0px !important;
  height: 54px;
  margin-top: 22px; //40 - 18px
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px;
  color: $bleu-edenred;

  .collapse {
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    ul {
      margin-bottom: 0px;

      @media (min-width: 992px) {
        padding-left: 170px;
      }
    }
  }
  .btn_home {
    text-decoration: none;

    .nav-item {
      display: flex;
      justify-content: center;
    }

    p {
      display: flex;
      align-items: center;
      padding: 0px;
      margin: 0px;
      font-weight: bold;
      font-size: 13px;
      line-height: 16px;

      i {
        margin-right: 10px;
        font-size: 18px;
      }
    }
  }

  .login-section {
    p {
      line-height: 28px;
      font-weight: normal;
      font-size: 16px;
    }
    .btn_login {
      margin-left: 16px;
      @media (min-width: 992px) {
        margin-right: 135px;
      }
      p {
        margin-bottom: 0px;
        font-weight: 600;
        font-size: 15px;
        line-height: 18px;
        padding: 13px 30px;
        border-width: 2px;
      }
    }
  }
}
