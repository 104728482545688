$arrow-size: 17.5px;

.arrow {
  width: $arrow-size;
  z-index: 10;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  &.arrow--prev {
    margin-right: $arrow-size;
  }

  &.arrow--next {
    margin-left: $arrow-size;
    transform: rotate(180deg);
  }

  &.arrow--disabled {
    opacity: 0.5;
  }

  path {
    stroke: var(--color);
  }
}
