@import 'src/_variable';

.project .project-content.lancement {
  .rect_info_title {
    margin-bottom: 14px;

    @media (min-width: 768px) {
      margin-bottom: 25px;
    }
  }

  button {
    color: white;
    font-weight: bold;
    font-size: 11px;
    line-height: 24px;
    padding: 0px 12px;
    margin-bottom: 22px;
  }

  h6 {
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 22px;
  }

  p {
    & > span {
      display: flex;
      align-items: flex-start;

      @media (min-width: 768px) {
        align-items: center;
      }

      svg {
        width: 12px;
        height: 12px;
        margin-right: 8px;
        margin-top: 5px;

        @media (min-width: 768px) {
          margin-top: 0px;
        }

        path {
          stroke: $bleu-edenred;
          stroke-width: 2px;
        }
      }
    }
  }
}
