@import 'src/_variable';

.project {
  h3 {
    font-weight: bold;
    font-size: 24px;
    line-height: 29px;
    margin-bottom: 16px;

    @media (min-width: 768px) {
      margin-top: 36px;
      margin-bottom: 16px;
      font-size: 30px;
      line-height: 37px;
    }
  }

  .project-infos {
    padding: 24px 16px 21px 16px;
    border-radius: 10px;
    margin-bottom: 16px;

    @media (min-width: 768px) {
      padding: 24px;
    }

    &:not(.project-infos-project) {
      box-shadow: 0px 10px 20px rgba(208, 214, 222, 0.5);
    }

    h5 {
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      color: $bleu-edenred;
      margin-bottom: 24px;

      @media (min-width: 768px) {
        font-size: 16px;
        line-height: 20px;
      }
    }

    .content {
      p {
        margin-bottom: 0px;
        font-weight: bold;
        font-size: 18px;
        line-height: 22px;
        color: $bleu-edenred;
        min-height: 0px;

        @media (min-width: 768px) {
          font-size: 20px;
          line-height: 24px;

          &:not(:last-child) {
            margin-bottom: 5px;
          }
        }

        &:not(:last-child) {
          margin-bottom: 3px;
        }
      }
    }

    &.project-infos-project {
      background: #eaeaea;
      padding-right: 0px;

      @media (min-width: 768px) {
        padding: 24px 32px;
      }

      .infos {
        width: 100%;
        padding-right: 15px;

        h5 {
          @media (min-width: 768px) {
            margin-bottom: 10px;
          }
        }

        .content {
          p {
            font-weight: 500;
            font-size: 16px;
            line-height: 20px;
            display: flex;
            align-items: flex-start;

            @media (min-width: 768px) {
              margin-bottom: 10px;
            }

            svg {
              width: 14px;
              height: 14px;
              margin-right: 11px;
              margin-top: 1px;

              path {
                stroke-width: 2px;
              }
            }

            &:not(:last-child) {
              margin-bottom: 3px;
            }
          }

          margin-bottom: 16px;
        }

        button[type='button'] {
          font-weight: 600;
          font-size: 12px;
          line-height: 15px;
          padding: 8px 12px;

          @media (min-width: 768px) {
            font-size: 15px;
            line-height: 18px;
          }
        }
      }

      .phone-content {
        border-left: 2px solid white;
        width: 95px;
        min-width: 95px;
        display: flex;
        justify-content: center;
        align-items: center;

        svg {
          width: 32px;
          height: 32px;

          path {
            stroke-width: 1px;
          }
        }

        a.disabled {
          opacity: 0.3;
        }
      }
    }
  }

  .slider-project {
    margin-bottom: 16px;
    border-bottom: 0px;

    @media (min-width: 540px) {
      margin-bottom: 18px;
    }

    .slick-list {
      overflow: visible;
      max-width: 100%;
      width: 100%;

      @media (min-width: 540px) {
        overflow: hidden;
        max-width: 100%;
      }
    }

    .slick-slide {
      margin-right: 6px;
    }

    .nav-link {
      height: 74px;
      width: 149px;
      min-width: 149px;
      background: $inactif;
      color: white;
      font-weight: bold;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
      box-shadow: 0px 10px 20px rgba(208, 214, 222, 0.5);
      border-radius: 10px;
      font-size: 14px;
      line-height: 17px;
      padding-left: 5px;
      padding-right: 5px;
      -webkit-user-select: none; /* Safari 3.1+ */
      -moz-user-select: none; /* Firefox 2+ */
      -ms-user-select: none; /* IE 10+ */
      user-select: none; /* Standard syntax */
      -webkit-user-drag: none;
      cursor: initial;

      &.passed:hover,
      &.passed:focus {
        background: $bleu-edenred !important;
        color: $gris !important;
        border: 1px solid transparent !important;
      }

      @media (min-width: 768px) {
        height: 120px;
        min-width: 186px;
        width: 186px;
        font-size: 16px;
        line-height: 20px;
      }

      &.active {
        background: $bleu-edenred;
        color: white;
      }

      span {
        font-weight: bold;
        font-size: 24px;
        line-height: 29px;

        @media (min-width: 768px) {
          margin-bottom: 10px;
          font-size: 36px;
          line-height: 44px;
        }
      }
    }
  }

  .project-content {
    min-height: 330px;
    @media (min-width: 768px) {
      padding: 43px 44px;
    }

    .rect_info_title {
      @media (min-width: 768px) {
        margin-bottom: 35px;
      }
    }

    @media (min-width: 768px) {
      .infos_chef {
        p:not(:last-child) {
          margin-bottom: 0px;
        }
      }
    }

    p {
      line-height: 22px;
    }
  }
}

.carousel_item.project-carousel-item {
  width: 150px;
  // margin-right: 5px !important;

  @media (min-width: 768px) {
    width: 186px;
    // margin-right: 14px !important;
  }
}

.custom-file-content {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 50px;
  .custom-file {
    margin-bottom: 6px;
    .custom-file-label {
      border: none;
      border-bottom: 2px solid $bleu-edenred;
      border-radius: 0px;
      color: $bleu-edenred;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      padding-left: 0px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 100%;
      display: flex;
      justify-content: space-between;
      &::after {
        display: none;
      }

      svg {
        width: 24px;
        height: 24px;
      }
    }
  }
  small {
    width: 100%;
    font-weight: normal;
    font-size: 11px;
    line-height: 18px;

    @media (min-width: 768px) {
      font-weight: normal;
      font-size: 12px;
      line-height: 17px;
    }
  }

  @media (min-width: 768px) {
    align-items: flex-end;
    margin-bottom: 32px;
    button {
      margin-bottom: 6px;
      font-weight: 600;
      font-size: 15px;
      line-height: 18px;
      text-align: center;
      padding: 0px 12px;
      border-width: 2px !important;
      height: 34px;
    }
  }
}

button.send {
  width: calc(100% - 30px);
  margin-left: 15px;
  font-weight: 600;
  font-size: 15px;
  line-height: 18px;
  text-align: center;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-bottom: 36px;

  @media (min-width: 768px) {
    max-width: 301px;
    padding-top: 15px;
    padding-bottom: 15px;
    margin-bottom: 23px;
  }
}

.files {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-top: 10px;

  .file {
    background: white;
    border: 2px solid $bleu-edenred;
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 10px;
    color: $bleu-edenred;
    overflow: hidden;
    white-space: nowrap;
    max-width: 32%;
    width: 32%;
    margin-right: 1.3%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    span {
      width: 80%;
      overflow: hidden;
    }
  }
}
